import { rteAppId, rteCardsTag } from 'utils/routes'
import { home } from 'react-icons-kit/fa/home'
import { mapO } from 'react-icons-kit/fa/mapO'
import { barChart } from 'react-icons-kit/fa/barChart'
import { question } from 'react-icons-kit/fa/question'
import { Icon } from 'react-icons-kit'
import { fileText } from 'react-icons-kit/fa/fileText'
import Folders from './Folders'
import { StyledButton } from './styles'
import { checkGlobalAccess } from "../../services/utils";

export const isLinkActive = (pathname, route, appId) => {
  const splitPath = pathname.split('/')[2]
  const listPath = pathname.split('/')[3]

  if (route === 'CARDS') {
    return splitPath && splitPath.includes('page')
  }
  if (route === 'LISTS') {
    return listPath && listPath.includes('list')
  }
  if (route === 'HOME') {
    return pathname === rteAppId(appId, 'HOME')
  }
  if (route === 'PHOTOBOOTHS') {
    return listPath && listPath.includes('selfiebooth')
  }
  if (route === 'ITEMS') {
    return listPath && listPath.includes('item')
  }
  if (route === 'TRIGGERS') {
    return listPath && listPath.includes('trigger')
  }
  if (route === 'INPUTS') {
    return listPath && listPath.includes('input')
  }
  return splitPath && splitPath.includes(route.toLowerCase())
}

export const getNavLinks = (label, appId, folderId, isMobile = false, userRoles, superadmin, onClick = () => {}) => {
  return [
    { route: 'HOME', message: 'home', icon: <Icon icon={home} />, hasAccess: checkGlobalAccess(appId, superadmin, userRoles, 'PageDashboard') || superadmin },
    {
      route: 'CARDS',
      message: 'cards',
      icon: <Icon icon={mapO} />,
      children: <Folders folderId={folderId} className={isMobile ? 'd-md-none' : ''} />,
      button: (
        <StyledButton id="button-all-pages" to={rteCardsTag(appId, 'all')} onClick={onClick}>
          {label}
        </StyledButton>
      ),
      id: 'link-pages',
      hasAccess: checkGlobalAccess(appId, superadmin, userRoles, 'PageCards') || superadmin
    },
    { route: 'DATA', message: 'data', icon: <Icon icon={barChart} />, hasAccess: checkGlobalAccess(appId, superadmin, userRoles, 'PageData') || superadmin },
    { route: 'LOGS', message: 'logs', icon: <Icon icon={fileText} /> , hasAccess: checkGlobalAccess(appId, superadmin, userRoles, 'PageLogs') || superadmin },
    { route: 'TUTORIAL', message: 'tutorial', icon: <Icon icon={question} />, hasAccess: checkGlobalAccess(appId, superadmin, userRoles, 'PageTutorial') || superadmin },
  ]
}

export const getModuleLinks = (superadmin, app) => {
  return [
    { route: 'LOCKS', message: 'locks', hasAccess: superadmin || !!app.access.Lock },
    { route: 'RANDOM_STORY', message: 'randomStory', hasAccess: superadmin || !!app.access.RandomStory },
    { route: 'PHOTOBOOTHS', message: 'photobooth', hasAccess: superadmin || !!app.access.Photobooth },
    { route: 'LISTS', message: 'lists', hasAccess: superadmin || !!app.access.List },
    { route: 'ITEMS', message: 'item', hasAccess: superadmin || !!app.access.ListItem },
    { route: 'INPUTS', message: 'input', hasAccess: superadmin || !!app.access.Input },
    { route: 'TRIGGERS', message: 'trigger', hasAccess: superadmin || !!app.access.Trigger },
  ]
}

export const getManageLinks = (appId, userRoles, superadmin) => {
  return [
    { route: 'MANAGEAPPS', message: 'manageapps', hasAccess: checkGlobalAccess(appId, superadmin, userRoles, 'PageManageApps') || superadmin },
    { route: 'MANAGEUSERS', message: 'manageusers', hasAccess: checkGlobalAccess(appId, superadmin, userRoles, 'PageManageUsers') || superadmin },
  ]
}
