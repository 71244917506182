import PropTypes from 'prop-types'
import { useState } from 'react'
import { StyledInputContainer, StyledButton, StyledErrorLabel, StyledContainer, StyledInput, LockImageAfter, LockImageBefore, LockThumbnailImage } from './styles'
import { addBrToText } from 'utils'

import { Icon } from 'react-icons-kit'
import { lock } from 'react-icons-kit/feather/lock'
import { check } from 'react-icons-kit/feather/check'
import { xCircle } from 'react-icons-kit/feather/xCircle'

const Lock = ({ values }) => {
  const [answer, setAnswer] = useState('')
  const [status, setStatus] = useState('')

  const handleSubmit = (e) => {
    e.preventDefault()
    const ans = answer.trim().toLowerCase()
    const res = values.answers.map((i) => i.trim().toLowerCase())

    if (res.includes(ans)) {
      setStatus('valid')
      const redirect = window.open(values.onSuccess.url, '_blank')
      redirect.focus()
    } else {
      setStatus('error')
    }
  }

  return (
    <>
      {values.display.headerBanner && <LockImageBefore><img src={values.display.headerBanner} alt="" /></LockImageBefore>}

      <StyledContainer $background={values.display.color}>
        {values.display.thumbnailImage && <LockThumbnailImage $borderRadius={values.style.borderRadius}><div><img src={values.display.thumbnailImage} alt="" /></div></LockThumbnailImage>}

        {values.display.title && values.display.title !== '' && <h2>{values.display.title}</h2>}

        {values.display.text && (
          <label htmlFor="lockpass" style={{ marginBottom: '20px' }}>
            {addBrToText(values.display.text, 'lock-txt')}
          </label>
        )}

        <StyledInputContainer error={status === 'error'} valid={status === 'valid'}>
          <Icon icon={lock} className="lock" />

          <StyledInput
            type="text"
            name="lockpass"
            id="lockpass"
            error={status === 'error'}
            valid={status === 'valid'}
            onChange={(e) => {
              setStatus('')
              setAnswer(e.target.value)
            }}
            autoComplete="off"
          />

          {status === 'valid' && <Icon className="state" icon={check} />}
          {status === 'error' && <Icon className="state" icon={xCircle} />}
        </StyledInputContainer>

        {status === 'error' && values.onError.action === 'message' && (
          <StyledErrorLabel htmlFor="lockpass">{values.onError.message}</StyledErrorLabel>
        )}

        <StyledButton onClick={handleSubmit}>
          <span style={{ fontWeight: '700' }}>{values.display.button}</span>
        </StyledButton>
      </StyledContainer>

      {values.display.footerBanner && <LockImageAfter><img src={values.display.footerBanner} alt="" /></LockImageAfter>}
    </>
  )
}

Lock.propTypes = {
  part: PropTypes.object,
}

export default Lock
