import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import { BasicInput, BasicTextArea, BasicInfo, BasicCollapsibleSection, BasicImageCrop, BasicLabel } from 'components'

import { StyledColorPicker, StyledLegend, WrapperForBasicImageCrop } from './styles'
import messages from '../messages'
import { useState } from 'react'
import { HEX_COLORS_LOCKS } from 'utils/consts'
import Circle from '@uiw/react-color-circle'


const GeneralInfo = ({ getLockByName, id, data, onChange }) => {
  const [selectedValue, setSelectedValue] = useState(data?.style?.borderRadius || '0')
  const [hex, setHex] = useState(data && data.display && data.display.color|| '#F1F1F1')

  const intl = useIntl()

  const handleValidation = (value) => {
    const locks = getLockByName(value) || []
    const differentId = locks.filter((l) => l.id !== id)
    return differentId.length <= 0
  }

  const handleSelectForBasicImageCropPreviewImageRadius = (e) => {
    setSelectedValue(e.target.value)
  }

  return (
    <>
      <BasicInput
        name="name"
        label={intl.formatMessage(messages.name)}
        error={intl.formatMessage(messages.nameError)}
        helpMessage={intl.formatMessage(messages.nameHelp)}
        placeholder={intl.formatMessage(messages.namePlaceholder)}
        required
        validation={{
          async: (value) => handleValidation(value),
        }}
      />

      <hr />

      <BasicCollapsibleSection label={intl.formatMessage(messages.headerLabel)}>
        <BasicInfo text={intl.formatMessage(messages.headerInfo)} />
        <BasicImageCrop
          required={false}
          name="display.headerBanner"
          file={data?.display?.headerBanner}
          label={intl.formatMessage(messages.headerBannerLabel)}
          helpMessage={intl.formatMessage(messages.headerBannerHelp)}
          variant="dropzone"
          preview="inline"
          aspectRatio={16 / 3}
        />

        <WrapperForBasicImageCrop>
          <BasicImageCrop
            required={false}
            name="display.thumbnailImage"
            file={data?.display?.thumbnailImage}
            label={intl.formatMessage(messages.thumbnailImageLabel)}
            helpMessage={intl.formatMessage(messages.thumbnailImageHelp)}
            variant="dropzone"
            preview="inline"
            onChange={onChange}
            previewImageInlineStyle={{ overflow: 'hidden', borderRadius: `${selectedValue}%` }}
          >
            <BasicInput
              type="select"
              onChange={handleSelectForBasicImageCropPreviewImageRadius}
              label=""
              helpMessage="Image arrondi"
              name="style.borderRadius"
              value={selectedValue}
            >
              <option value="0">{intl.formatMessage(messages.BorderRadius0)}</option>
              <option value="10">{intl.formatMessage(messages.BorderRadius10)}</option>
              <option value="25">{intl.formatMessage(messages.BorderRadius25)}</option>
              <option value="50">{intl.formatMessage(messages.BorderRadius50)}</option>
            </BasicInput>
          </BasicImageCrop>
        </WrapperForBasicImageCrop>
      </BasicCollapsibleSection>

      <hr />

      <StyledLegend>{intl.formatMessage(messages.content)}</StyledLegend>

      <BasicInfo text={intl.formatMessage(messages.contentHelp)} />

      <BasicInput
        name="display.title"
        label={intl.formatMessage(messages.title)}
        error={intl.formatMessage(messages.titleError)}
        helpMessage={intl.formatMessage(messages.titleHelp)}
        placeholder={intl.formatMessage(messages.titlePlaceholder)}
        required
      />

      <BasicTextArea
        name="display.text"
        label={intl.formatMessage(messages.text)}
        helpMessage={intl.formatMessage(messages.textHelp)}
        placeholder={intl.formatMessage(messages.textPlaceholder)}
        size="small"
      />

      <BasicInput
        name="display.button"
        label={intl.formatMessage(messages.buttonText)}
        error={intl.formatMessage(messages.buttonTextError)}
        helpMessage={intl.formatMessage(messages.buttonTextHelp)}
        placeholder={intl.formatMessage(messages.buttonTextPlaceholder)}
        required
      />

      <StyledColorPicker>
        <BasicInput
          name="display.color"
          value={hex}
          type="hidden"
        />
        <BasicLabel
          label={intl.formatMessage(messages.color)}
          helpMessage={intl.formatMessage(messages.colorHelp)}
        />
        <Circle color={hex} onChange={(color) => setHex(color.hex)} colors={HEX_COLORS_LOCKS} />
      </StyledColorPicker>

    </>
  )
}

GeneralInfo.defaultProps = {}

GeneralInfo.propTypes = {
  id: PropTypes.string,
  getLockByName: PropTypes.func,
  data: PropTypes.object,
}

export default GeneralInfo
