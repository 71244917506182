import { StyledButton, StyledContent, StyledIcon, StyledText } from '../styles'
import { BasicButton } from '../../index'
import messages from '../messages'
import { Icon } from 'react-icons-kit'
import { image as iconImage } from 'react-icons-kit/feather/image';
import { useIntl } from 'react-intl'

const VariantUploadDropzone = ({onClick}) => {
  const intl = useIntl()

  return (
    <StyledContent onClick={onClick}>
      <StyledText>
        <StyledIcon>
          <Icon icon={iconImage} />
        </StyledIcon>

        <span>{intl.formatMessage(messages.dropMessage)}</span>
      </StyledText>

      <StyledButton>
        <BasicButton
          color="green"
          small
          outlined
          label={intl.formatMessage(messages.browse)}
        />
      </StyledButton>
    </StyledContent>
  );
};

VariantUploadDropzone.defaultProps = {
};

VariantUploadDropzone.propTypes = {
};


export default VariantUploadDropzone;
