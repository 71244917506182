import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { StyledContainer, StyledElement, StyledInput, StyledSelect } from './styles'
import DateFilter from './dateFilters'
import { useIntl } from 'react-intl'
import messages from './messages'

const SearchLogs = ({ searchQuery, setSearchQuery }) => {
  const [user, setUser] = useState(searchQuery.user || '')
  const [content, setContent] = useState(searchQuery.content || '')
  const [action, setAction] = useState(searchQuery.action || '')
  const [where, setWhere] = useState(searchQuery.where || '')
  const [dateRange, setDateRange] = useState(searchQuery.dateRange || [null, null])

  const intl = useIntl();

  useEffect(() => {
    document.addEventListener('keydown', handleOnKeyDown)

    return () => {
      document.removeEventListener('keydown', handleOnKeyDown)
    }
  }, [])

  useEffect(() => {
    setSearchQuery({ user, content, action, where, dateRange })
  }, [user, content, action, where, dateRange, setSearchQuery])

  const handleOnKeyDown = (e) => {
    if (e.key === 'Enter') {
      setSearchQuery({ user, content, action, where, dateRange })
    }
  }

  const handleActionChange = (e) => {
    const selectedAction = e.target.value;
    setAction(selectedAction);
  }

  return (
    <StyledContainer>
      <StyledElement>
        <p>{intl.formatMessage(messages.filterEmail)}</p>
        <StyledInput
          type="text"
          value={user}
          onChange={(e) => setUser(e.target.value)}
        />
      </StyledElement>
      <StyledElement>
        <p>{intl.formatMessage(messages.filterContent)}</p>
        <StyledInput
          type="text"
          value={content}
          onChange={(e) => setContent(e.target.value)}
        />
      </StyledElement>
      <StyledElement>
        <p>{intl.formatMessage(messages.filterAction)}</p>
        <StyledSelect
          value={searchQuery.action}
          onChange={handleActionChange}>
          <option value=""></option>
          <option value="show">Show</option>
          <option value="save">Save</option>
          <option value="delete">Delete</option>
          <option value="duplicate">Duplicate</option>
          <option value="list">Liste</option>
        </StyledSelect>
      </StyledElement>
      <StyledElement>
        <p>{intl.formatMessage(messages.filterUrl)}</p>
        <StyledInput
          type="text"
          value={where}
          onChange={(e) => setWhere(e.target.value)}
        />
      </StyledElement>
      <StyledElement>
        <p>{intl.formatMessage(messages.filterPeriod)}</p>
        <DateFilter value={dateRange} onChange={setDateRange} />
      </StyledElement>
    </StyledContainer>
  )
}

SearchLogs.defaultProps = {
  searchQuery: {},
  setSearchQuery: () => {},
}

SearchLogs.propTypes = {
  placeholder: PropTypes.string,
  searchQuery: PropTypes.object,
  setSearchQuery: PropTypes.func,
}

export default SearchLogs
