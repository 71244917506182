import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import { Lock as LockPreview, BasicButton } from 'components'
import { rteLockId } from 'utils/routes'
import styled, { css } from 'styled-components'

import connectStore from 'redux-box/dist/connectStore'
import { storeModule as LockModule } from 'store/locks'

import { Icon } from 'react-icons-kit'
import { lock } from 'react-icons-kit/feather/lock'
import { externalLink } from 'react-icons-kit/feather'

import { NotFound } from '../modules'
import { ScheduleTooltip } from '../components/Tooltips'
import messages from './messages'

const StyledWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: ${({ type }) => (type === 'Lock' ? 'center' : 'space-between')};
  margin: ${({ type }) => (type === 'Lock' ? '0px' : '20px')};

  @media (max-width: 460px) {
    ${({ type }) => type === 'Lock' && 'flex-direction: column-reverse'};
  }

  ${({ singleview, type }) =>
    !singleview &&
    type === 'Lock' &&
    css`
      flex-direction: column-reverse;
    `}
`

const StyledTooltip = styled.div`
  position: absolute;
  right: 0;

  @media (max-width: 460px) {
    position: relative;
    align-self: flex-end;
    padding-bottom: 5px;
  }

  ${({ singleview, type }) =>
    !singleview &&
    type === 'Lock' &&
    css`
      position: relative;
      align-self: flex-end;
      padding-bottom: 5px;
    `}
`

const StyledLock = styled.div`
  display: flex;
  width: 100%;
  font-size: 16px;
  line-height: 18px;
  font-weight: 600;
  font-family: 'Nunito', serif;
  ${({ options }) => options && 'padding-right: 40px'};

  i {
    display: flex !important;
    margin-right: 5px;
  }
`

const LockPreviewWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Lock = ({ appId, modules, part, partIndex, singleview }) => {
  const hasOptions = part.options && part.options.display && part.options.display.length !== {}
  const foundLock = modules.getLockById(part.id) || {}
  const intl = useIntl()

  return (
    <>
      <StyledWrapper key={partIndex} type={part.type} singleview={singleview}>
        {Object.keys(foundLock).length > 0 ? (
          part.type === 'Lock' ? (
            <LockPreviewWrapper singleview={singleview}>
              {part.type === 'Lock' && (
                <div style={{ alignSelf: 'flex-start', marginTop: '10px' }}>
                  <BasicButton
                    label={intl.formatMessage(messages.viewLock)}
                    icon={<Icon icon={externalLink} />}
                    isLink
                    href={rteLockId(appId, part.id)}
                    target="_blank"
                    linkstyle
                    small
                  />
                </div>
              )}

              <LockPreview values={foundLock} />
            </LockPreviewWrapper>
          ) : (
            <StyledLock options={hasOptions}>
              <Icon icon={lock} />
              <span>
                {intl.formatMessage(messages[part.type])}:&nbsp;{foundLock.name}
              </span>
            </StyledLock>
          )
        ) : (
          <NotFound id={part.id} type={intl.formatMessage(messages.Lock)} fetching={modules.fetching} />
        )}

        <StyledTooltip singleview={singleview} type={part.type}>
          <ScheduleTooltip part={part} />
        </StyledTooltip>
      </StyledWrapper>
    </>
  )
}

Lock.propTypes = {
  appId: PropTypes.string,
  part: PropTypes.object,
  partIndex: PropTypes.number,
  singleview: PropTypes.bool,
}

export default connectStore({ modules: LockModule })(Lock)
