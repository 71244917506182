/* eslint-disable array-callback-return */
import { createSagas } from 'redux-box'
import { call, put } from 'redux-saga/effects'
import rsFirebase from 'services/rsf'
import {saveApp} from '../app/workers'

export default createSagas({
  *DATA_GET_THEMES() {
    try {
      yield put({ type: 'DATA_FETCHING' })
      const dataSnap = yield call(rsFirebase.firestore.getDocument, `options/themes`)
      const data = dataSnap.data()
      yield put({ type: 'DATA_SET_THEMES', data })
      yield put({ type: 'DATA_FETCH_SUCCESS' })
    } catch (error) {
      yield put({ type: 'DATA_FETCH_ERROR' })
    }
  },
  *DATA_SAVE_THEME({ theme }) {
    try {
      yield put({ type: 'DATA_SAVING' })
      yield put({ type: 'DATA_SET_THEME', theme })
      yield put({ type: 'DATA_SAVE_SUCCESS' })
    } catch (error) {
      yield put({ type: 'DATA_SAVE_ERROR' })
    }
  },
  *DATA_DELETE_THEME({ theme }) {
    try {
      yield put({ type: 'DATA_DELETING' })

      // DB - delete from data.themes
      yield call(rsFirebase.firestore.deleteDocument, `options/themes/${theme.value}`)

      // DB - delete theme from apps[app].themes + apps[app].theme
      let appsSnap = yield call(rsFirebase.firestore.getCollection, `appdata`)
      let apps = {}
      appsSnap.forEach((app) => {
        apps[app.id] = app.data()
      })
      Object.values(apps).map((app) => {
        if (app.themes) {
          const t = app.themes.filter((t) => t !== theme.value)
          apps[app.id].themes = t
        }
        if (app.theme && app.theme === theme.value) {
          apps[app.id].theme = app.id
        }
      })

      apps.forEach((app) => {
        Object.values(app.cards).map((card) => {
          if (card.theme === theme.value) {
            app.cards.card.theme = ''
          }
        })
        saveApp(app)
      })

      // store - remove from management: management.apps.list
      yield put({ type: 'MANAGE_APPS_REMOVE_THEME', theme })

      // store - remove from app: app.theme + app.themes
      yield put({ type: 'APP_REMOVE_THEME', theme })

      // store - remove from data: data.themes
      yield put({ type: 'DATA_REMOVE_THEME', theme })
      yield put({ type: 'DATA_DELETE_SUCCESS' })
    } catch (error) {
      yield put({ type: 'DATA_DELETE_ERROR' })
    }
  },
})
