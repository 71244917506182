import PropTypes from 'prop-types'
import {
  StyledTable,
  StyledTd,
  StyledTh,
  StyledThead,
  StyledTr,
} from './styles'
import { useIntl } from 'react-intl'
import messages from './messages'
import { useState } from 'react'
import { CARDS_TO_DISPLAY } from '../../utils/consts'
import InfiniteScroll from 'react-infinite-scroll-component'

const LogsSession = ({ list }) => {
  const [max, setMax] = useState(CARDS_TO_DISPLAY)
  const intl = useIntl();

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleString();
  }

  return (
    <>
      <InfiniteScroll
        dataLength={max}
        next={() => setMax(max + CARDS_TO_DISPLAY)}
        hasMore={list.length >= max}
        loader={<p>{intl.formatMessage(messages.loading)}</p>}
      >
        <StyledTable>
          <StyledThead>
            <StyledTr>
              <StyledTh></StyledTh>
              <StyledTh>{intl.formatMessage(messages.date)}</StyledTh>
              <StyledTh>{intl.formatMessage(messages.user)}</StyledTh>
              <StyledTh>{intl.formatMessage(messages.action)}</StyledTh>
            </StyledTr>
          </StyledThead>
          <tbody>
          {list.slice(0, max).map((log, index) => (
            <StyledTr key={index}>
              <StyledTd>{log.id}</StyledTd>
              <StyledTd>{formatDate(log.date)}</StyledTd>
              <StyledTd>{log.userFirstName} {log.userLastName}<br />{log.userEmail}</StyledTd>
              <StyledTd>{log.action}</StyledTd>
            </StyledTr>
          ))}
          </tbody>
        </StyledTable>
      </InfiniteScroll>
    </>
  )
}

LogsSession.propTypes = {
  list: PropTypes.array,
}

export default LogsSession
