import { useIntl } from 'react-intl';

import { BasicButton } from '../../index'

import messages from '../messages'

const VariantUploadButton = ({onClick}) => {
  const intl = useIntl()

  return (
    <BasicButton
      color="green"
      small
      outlined
      label={intl.formatMessage(messages.browse)}
      onClick={onClick}
    />
  )
};

VariantUploadButton.defaultProps = {
};

VariantUploadButton.propTypes = {
};


export default VariantUploadButton;
