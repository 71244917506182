import PropTypes from 'prop-types'
import { BasicInput } from 'components'
import { useIntl } from 'react-intl'
import messages from './messages'
import { StyledButtonHalf, StyledLegend } from './styles'
import { useState } from 'react'

const ButtonStyleForm = ({
  onChange,
  legend,
  style,
  url,
  displayBorderRadius,
  displayBorderWidth,
  displayNbrPerCol,
}) => {
  const { borderRadius, borderWidth, nbrPerCol } = style
  const [buttonStyle, setButtonStyle] = useState({
    borderRadius: borderRadius || '50',
    borderWidth: borderWidth || '3',
    url: url || '',
  });

  const intl = useIntl()

  const handleOnChange = (e) => {
    const { name, value } = e.target;

    setButtonStyle({
      ...buttonStyle,
      [name.replace('style.', '')]: value
    });

    return onChange(e)
  }

  return (
    <>
      {legend && <StyledLegend>{intl.formatMessage(messages.buttonStyle)}</StyledLegend>}

      <div style={{ width: '100%', display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
        <StyledButtonHalf
          borderWidth={buttonStyle.borderWidth}
          borderRadius={buttonStyle.borderRadius}
          url={buttonStyle.url}
        />
      </div>

      {displayBorderRadius && (
        <BasicInput
          type="select"
          onChange={handleOnChange}
          label={intl.formatMessage(messages.BorderRadiusLabel)}
          helpMessage={intl.formatMessage(messages.BorderRadiusHelp)}
          name="style.borderRadius"
          value={borderRadius || '50'}
        >
          <option value="0">{intl.formatMessage(messages.BorderRadius0)}</option>
          <option value="10">{intl.formatMessage(messages.BorderRadius10)}</option>
          <option value="25">{intl.formatMessage(messages.BorderRadius25)}</option>
          <option value="50">{intl.formatMessage(messages.BorderRadius50)}</option>
        </BasicInput>
      )}

      {displayBorderWidth && (
        <BasicInput
          type="select"
          onChange={handleOnChange}
          label={intl.formatMessage(messages.BorderWidthLabel)}
          helpMessage={intl.formatMessage(messages.BorderWidthHelp)}
          name="style.borderWidth"
          value={borderWidth || '3'}
        >
          <option value="0">{intl.formatMessage(messages.BorderWidth0)}</option>
          <option value="1">{intl.formatMessage(messages.BorderWidth1)}</option>
          <option value="2">{intl.formatMessage(messages.BorderWidth2)}</option>
          <option value="3">{intl.formatMessage(messages.BorderWidth3)}</option>
        </BasicInput>
      )}

      {displayNbrPerCol && (
        <BasicInput
          type="select"
          onChange={handleOnChange}
          label={intl.formatMessage(messages.NbrPerColLabel)}
          helpMessage={intl.formatMessage(messages.NbrPerColHelp)}
          name="style.nbrPerCol"
          value={nbrPerCol || '2'}
        >
          <option value="2">{intl.formatMessage(messages.NbrPerCol2)}</option>
          <option value="3">{intl.formatMessage(messages.NbrPerCol3)}</option>
          <option value="4">{intl.formatMessage(messages.NbrPerCol4)}</option>
        </BasicInput>
      )}
    </>
  )
}

ButtonStyleForm.defaultProps = {
  onChange: () => {},
  style: {},
  index: '',
  legend: true,
  displayBorderRadius: true,
  displayBorderWidth: true,
  displayNbrPerCol: true,
}

ButtonStyleForm.propTypes = {
  onChange: PropTypes.func,
  style: PropTypes.object,
  url: PropTypes.string,
  displayBorderRadius: PropTypes.bool,
  displayBorderWidth: PropTypes.bool,
  displayNbrPerCol: PropTypes.bool,
  legend: PropTypes.bool
}

export default ButtonStyleForm
