import styled, { css } from 'styled-components'

const getPadding = (props) => {
  if (props.size === 'small') {
    return '5% 15%'
  }
  if (props.size === 'medium') {
    return '8% 6% 6% 6%'
  }
  return '7% 30% 5% 30%'
}

export const StyledDropZone = styled.div`
    margin-top: 5px;
    margin-bottom: 20px;

    @media (max-width: 579px) {
        padding-top: 30px !important;
    }
    
    & > div {
        background: white;
        border: 2px dashed ${(props) => (props?.isDragReject ? '#fe0045' : '#34b076')};
        padding: ${(props) => getPadding(props)};
        border-radius: 8px;
        cursor: pointer;
        transition: all 0.25s linear;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
`

export const StyledContent = styled.div`
    display: flex;
    flex-direction: column;

    ${({ disabled }) =>
            disabled &&
            css`
                background: #f4f4f4 !important;
                border-color: #cacaca !important;
                color: #cacaca !important;
                cursor: default !important;
                pointer-events: none !important;
            `};

    ${({ error }) =>
            error &&
            css`
                border-color: #ef466f !important;
                color: #ef466f !important;
            `};
`

export const StyledIcon = styled.div`
    ${({ size }) => size === 'small' && 'display: none'};

    &:before {
        content: '';
        position: absolute;
        margin-top: -11px;
        margin-left: -79px;
        border: 2px dashed #dddddd;
        border-radius: 8px;
        height: 58px;
        width: 121px;
        background: linear-gradient(to right, #ffffff 0%, #efefef 100%);

        @media (max-width: 579px) {
            height: 46px;
            width: 95px;
        }
    }

    i {
        transform: rotate(-25deg);
        margin-left: -100px;
        margin-bottom: 20px;
    }

    svg {
        width: 40px;
        height: 40px;
        background-color: white;

        @media (max-width: 579px) {
            height: 30px;
            width: 30px;
        }
    }
`

export const StyledText = styled.span`
    font-family: Nunito, sans-serif;
    font-size: ${(props) => (props?.size === 'small' ? '16px' : '22px')};
    font-weight: 600;
    line-height: 22px;
    color: #34b076;

    @media (max-width: 579px) {
        font-size: ${(props) => (props.size === 'small' ? '14px' : '18px')};
    }
`

export const StyledError = styled.span`
    font-size: 12px;
    margin-top: 10px;
    margin-bottom: -28px;
    color: #dc3545;
`

export const StyledButton = styled.div`
    ${({ size }) => size === 'small' && 'display: none'};
    margin: 20px 0 0 0;
`

// comes from class invalid-feedback in bootstrap file
export const StyledErrorMsg = styled.p`
    opacity: 1;
    width: 100%;
    margin-top: -20px;
    font-size: 12px;
    color: #dc3545;
`

export const StyledPreviewContainer = styled.div`
    display: flex;
    align-items: flex-start;
    margin-top: 10px;
    margin-bottom: 20px;

    @media (max-width: 600px) {
        justify-content: flex-start;
        padding: 0;
    }

    ${({ disabled }) =>
            disabled &&
            css`
                cursor: default !important;
                pointer-events: none !important;
            `};
`

export const StyledPreviewImage = styled.div`
    width: min-content;

    img {
        width: 100%;
        height: 100%;
        min-width: 300px;
        max-height: 300px;
        max-width: 90%;
        object-fit: cover;
    }
  `

export const StyledPreviewButton = styled.div`
  position: relative;
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;

  @media (max-width: 600px) {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
  }
`

export const StyledPreviewFile = styled.img`
  position: absolute;
  width: 30px;
  height: 30px;
  top: 40px;
`

export const WrapperIconCropper = styled.div `
    background: #ffffff8c;
    border-radius: 5px;
    box-shadow: 2px 2px 4px 0 #CCC;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #FFF;
    margin-left: 5px;
    margin-right: 10px;
    z-index: 100;
`

export const ContainerIconCropper = styled.div `
    position: absolute;
    bottom: 100px;
    left: 20px;
    right: 20px;
    width: auto;
    display: flex;
    justify-content: space-between;
`

export const WrapperDropzone = styled.div `
  display: flex;
  flex-direction: column;
`
