import { useEffect, useState } from "react";

export function useCountModules(list) {
  const [counter, setCounter] = useState({
    h2: 0,
    text: 0,
    separator: 0,
    languageLink: 0,
    picture: 0,
    pictureUrl: 0,
    giphy: 0,
    sketchFab: 0,
    audio: 0,
    soundCloud: 0,
    video: 0,
    videoYoutube: 0,
    vimeo: 0,
    button: 0,
    buttonHalf: 0,
    facebookShareButton: 0,
    buttonUnity: 0,
    googleMaps: 0,
    formGoogle: 0,
    redirect: 0,
    random: 0,
    randomCancel: 0,
    randomStory: 0,
    randomStoryReset: 0,
    lock: 0,
    lockCancel: 0,
    lockPage: 0,
    voxpop: 0,
    photobooth: 0,
    list: 0,
    trigger: 0,
    listItem: 0,
    input: 0,
  });

  useEffect(() => {
    if (list) {
      const newCounter = {
        h2: 0,
        text: 0,
        separator: 0,
        languageLink: 0,
        picture: 0,
        pictureUrl: 0,
        giphy: 0,
        sketchFab: 0,
        audio: 0,
        soundCloud: 0,
        video: 0,
        videoYoutube: 0,
        vimeo: 0,
        button: 0,
        buttonHalf: 0,
        facebookShareButton: 0,
        buttonUnity: 0,
        googleMaps: 0,
        formGoogle: 0,
        redirect: 0,
        random: 0,
        randomCancel: 0,
        randomStory: 0,
        randomStoryReset: 0,
        lock: 0,
        lockCancel: 0,
        lockPage: 0,
        voxpop: 0,
        photobooth: 0,
        list: 0,
        trigger: 0,
        listItem: 0,
        input: 0,
      };

      list.forEach(card => {
        card.parts && card.parts.forEach(part => {
          switch (part.type) {
            case 'H2':
              newCounter.h2 += 1;
              break;
            case 'Text':
              newCounter.text += 1;
              break;
            case 'Separator':
              newCounter.separator += 1;
              break;
            case 'LanguageLink':
              newCounter.languageLink += 1;
              break;
            case 'Picture':
              newCounter.picture += 1;
              break;
            case 'PictureUrl':
              newCounter.pictureUrl += 1;
              break;
            case 'Giphy':
              newCounter.giphy += 1;
              break;
            case 'SketchFab':
              newCounter.sketchFab += 1;
              break;
            case 'Audio':
              newCounter.audio += 1;
              break;
            case 'SoundCloud':
              newCounter.soundCloud += 1;
              break;
            case 'Video':
              newCounter.video += 1;
              break;
            case 'VideoYoutube':
              newCounter.videoYoutube += 1;
              break;
            case 'Vimeo':
              newCounter.vimeo += 1;
              break;
            case 'Button':
              newCounter.button += 1;
              break;
            case 'Button-Half':
              newCounter.buttonHalf += 1;
              break;
            case 'FacebookShareButton':
              newCounter.facebookShareButton += 1;
              break;
            case 'BtnUnity':
              newCounter.buttonUnity += 1;
              break;
            case 'GoogleMaps':
              newCounter.googleMaps += 1;
              break;
            case 'FormGoogle':
              newCounter.formGoogle += 1;
              break;
            case 'Redirect':
              newCounter.redirect += 1;
              break;
            case 'Random':
              newCounter.random += 1;
              break;
            case 'RandomReset':
              newCounter.randomCancel += 1;
              break;
            case 'RandomStory':
              newCounter.randomStory += 1;
              break;
            case 'RandomStoryReset':
              newCounter.randomStoryReset += 1;
              break;
            case 'Lock':
              newCounter.lock += 1;
              break;
            case 'LockCancel':
              newCounter.lockCancel += 1;
              break;
            case 'LockPage':
              newCounter.lockPage += 1;
              break;
            case 'Voxpop':
              newCounter.voxpop += 1;
              break;
            case 'Photobooth':
              newCounter.photobooth += 1;
              break;
            case 'List':
              newCounter.list += 1;
              break;
            case 'Trigger':
              newCounter.trigger += 1;
              break;
            case 'ListItem':
              newCounter.listItem += 1;
              break;
            case 'Input':
              newCounter.input += 1;
              break;
            default:
              break;
          }
        });
      });
      setCounter(newCounter);
    }
  }, [list]);

  return counter;
}
