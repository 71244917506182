import sortBy from 'lodash/sortBy'
import { orderByUpdateTime } from 'utils'

const getCardByIdSelector = ({ list }, cardId) => list.filter((cc) => cc.cardId === cardId)[0]

const getFeedbackCardsSelector = ({ list }, feedbackId) =>
  list.filter((cc) => cc.cardId === feedbackId || (cc.parent && cc.parent === feedbackId))

const getCardsByLockId = ({ list }, id) => {
  const lockPartTypes = ['LockCancel', 'Lock', 'LockPage']
  const partIsALock = (part) => lockPartTypes.includes(part.type)
  const partHasLock = (part) => part.id === id

  const cardContainLock = (card) =>
    card.parts &&  card.parts.filter((part) => partIsALock(part) && partHasLock(part)).length > 0

  return list.filter(cardContainLock)
}

const getCardsByRandomStoryId = ({ list }, id) => {
  const randomStoryPartTypes = ['RandomStory', 'RandomStoryReset']
  const partIsARandomStory = (part) => randomStoryPartTypes.includes(part.type)
  const partHasRandomStory = (part) => part.id === id

  const cardContainRandomStory = (card) =>
    card.parts.filter((part) => partIsARandomStory(part) && partHasRandomStory(part)).length > 0

  return list.filter(cardContainRandomStory)
}

const getCardsByPhotoboothId = ({ list }, id) => {
  const cards = []
  list.map(
    (card) =>
      card && card.parts && card.parts.map((p) => p.type === 'Photobooth' && p.id === id && cards.push(card))
  )
  return cards
}

const getCardsByType = (tagId, currentFolder) => (card) => {
  const { tokenId, folders } = card

  if (currentFolder && Object.keys(currentFolder).length > 0) {
    const cardIsInFolder = folders && folders.find((f) => f === currentFolder.id)
    if (!cardIsInFolder) return null
  }
  switch (tagId) {
    case 'all':
      return card
    case 'unlinked':
      return !tokenId || tokenId === '' || tokenId === 0 ? card : null
    case undefined:
    case 'linked':
      return tokenId && tokenId !== '' && tokenId !== 0 && tokenId !== "0" ? card : null
    default:
      return card
  }
}

const getCardList = ({ list }, tagId, currentFolder) => {
  const orderedList = sortBy(list, ['tokenId', 'cardId'])
  const filteredList = orderedList.filter(getCardsByType(tagId, currentFolder))
  if (tagId === 'unlinked' || tagId === 'all') {
    return orderByUpdateTime(filteredList)
  }
  return filteredList
}

const selectors = {
  getCardList: (state) => (tagId, currentFolder) => getCardList(state, tagId, currentFolder),
  getCardById: (state) => (id) => getCardByIdSelector(state, id),
  getFeedbackCards: (state) => (feedbackId) => getFeedbackCardsSelector(state, feedbackId),
  getCardsByLockId: (state) => (id) => getCardsByLockId(state, id),
  getCardsByRandomStoryId: (state) => (id) => getCardsByRandomStoryId(state, id),
  getCardsByPhotoboothId: (state) => (id) => getCardsByPhotoboothId(state, id),
}
export default selectors
