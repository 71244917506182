import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import { useState, useEffect } from 'react'
import orderBy from 'lodash/orderBy'

import connectStore from 'redux-box/dist/connectStore'
import { storeModule as AppModule } from 'store/app'
import { storeModule as CardModule } from 'store/card'
import { storeModule as DataModule } from 'store/data'

import { Icon } from 'react-icons-kit'
import { magic } from 'react-icons-kit/fa/magic'

import messages from '../messages'
import { StyledInput } from './styles'
import BasicInput from "../../BasicInput";
import { validationsPatterns } from 'utils'
import BasicCheckbox from "../../BasicCheckbox";
import {ic_launch} from 'react-icons-kit/md/ic_launch'
import styled from 'styled-components'
import BasicImageCrop from '../../BasicImageCrop'

const StyledInfo = styled.div`
    border-radius: 100%;
    width: 16px;
    height: 16px;
    border: 1px solid rgb(123, 156, 208);
    line-height: 14px;
    text-align: center;
    font-size: 12px;
    font-weight: bold;
    color: rgb(123, 156, 208);
    margin-left: 5px;
    cursor: pointer;
  `

const Settings = ({ app, card, data, handleChange, onCheckedChange, onFormChange }) => {
  const [cardTheme, setCardTheme] = useState('')

  const intl = useIntl()

  const getCardTheme = () => {
    let res = ''
    if (card.theme) {
      res = card.theme
    } else if (app.theme && getThemes().find((t) => t.value === app.theme)) {
      res = app.theme
    } else {
      Object.values(getThemes()).forEach((t) => {
        if (t.value === app.id) {
          res = app.id
        }
      })
    }

    if (res.length > 0) {
      res = res.replaceAll('-', '_')
      const themeExists = Object.values(getThemes()).filter((t) => t.value === res)
      if (themeExists.length > 0) return themeExists
    }

    return Object.values(getThemes()).filter((t) => t.value === 'msj_logo')
  }

  const getThemes = () => {
    let themes = []
    // eslint-disable-next-line array-callback-return
    Object.values(data.themes).map((t) => {
      if (app.themes.indexOf(t.value) !== -1) {
        themes.push(t)
      } else if (t.value === app.id) {
        themes.push(t)
      }
    })
    return orderBy(themes, 'name', 'asc')
  }

  useEffect(() => {
    const themeObject = getCardTheme()[0]
    setCardTheme(themeObject ? themeObject.value : [])
  }, [card])

  return (
    <>
      <BasicInput
        label={
          <>
            <Icon icon={ic_launch}/>
            <span style={{display:'inline-flex'}}>
              {intl.formatMessage(messages.linkLogo)}
              <StyledInfo title={intl.formatMessage(messages.linkLogoInfo)}>?</StyledInfo>
            </span>
          </>
        }
        type="text"
        name="linkLogo"
        value={card.linkLogo}
        error={intl.formatMessage(messages.linkLogoError)}
        validation={{
          pattern: validationsPatterns.urlExtended,
        }}
        onChange={handleChange}
        style={{border:'1px solid white', height: '35px', backgroundColor: '#12263f', color:'white'}}
      />

      <BasicCheckbox
        label={intl.formatMessage(messages.blankLinkLogo)}
        name="openBlankLinkLogo"
        checked={card.openBlankLinkLogo}
        value={card.openBlankLinkLogo}
        onChange={onCheckedChange}
      />

      {app.access.Favicon && (
        <BasicImageCrop
          variant={'button'}
          required={false}
          name="favicon"
          file={card.favicon}
          label="Fav icon"
          helpMessage={intl.formatMessage(messages.faviconHelpMessage)}
          onChange={(e) => {
            handleChange(e);
            onFormChange(true);
          }}
        />
      )}

      <StyledInput
        label={
          <>
            <Icon icon={magic} />
            {intl.formatMessage(messages.theme)}
          </>
        }
        type="select"
        name="theme"
        id="theme"
        placeholder="Theme"
        value={cardTheme}
        onChange={(e) => {
          handleChange(e);
          onFormChange(true);
        }}
        inputClassName="--white"
      >
        {Object.values(getThemes()).map((t, i) => (
          <option value={t.value} key={i}>
            {t.name}
          </option>
        ))}
      </StyledInput>
    </>
  )
}

Settings.propTypes = {
  app: PropTypes.object.isRequired,
  card: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  onCheckedChange: PropTypes.func.isRequired,
  onFormChange: PropTypes.func,
}

export default connectStore({ app: AppModule, card: CardModule, data: DataModule })(Settings)
