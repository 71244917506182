import rsFirebase from 'services/rsf'
import firebase from 'firebase/app';
import 'firebase/auth';
import { call, put } from 'redux-saga/effects'
import { createSagas } from 'redux-box'

function* getCurrentUser() {
  return yield new Promise((resolve, reject) => {
    const unsubscribe = firebase.auth().onAuthStateChanged(user => {
      unsubscribe();
      if (user) {
        resolve(user);
      } else {
        reject(new Error('User not authenticated'));
      }
    });
  });
}

export default createSagas({
  *LOGS_ADD_LOGS_CONTENT({ appId, action, where, typeContent, content }) {
    try {
      const userAuth = yield call(getCurrentUser);
      const userSnapshot = yield call(rsFirebase.firestore.getDocument, `/users/${userAuth.uid}/`)
      const user = userSnapshot.data()

      const countSnapshot = yield call(rsFirebase.firestore.getCollection, `logs/${appId}/logs`);
      const size = countSnapshot.size;

      const data = {
        id: size + 1,
        userId: user.id,
        userEmail: user.email,
        userLastName: user.lastName,
        userFirstName: user.firstName,
        date: Date.now(),
        action: action,
        where: where,
        typeContent: typeContent,
        content: content
      }

      yield call(rsFirebase.firestore.setDocument, `logs/${appId}/logs/${size+1}`, data);
      yield put({ type: 'LOGS_ADD_LOGS_SUCCESS' })
      if(
        (typeContent === 'folder' && action === 'show') ||
        (
          (typeContent === 'locks' ||
            typeContent === 'randomStory' ||
            typeContent === 'inputs' ||
            typeContent === 'items' ||
            typeContent === 'lists' ||
            typeContent === 'triggers' ||
            typeContent === 'selfiebooths') &&
          (action === 'show' || action === 'list')
        )
      ) {
        yield put({ type: 'LOGS_ADD_LOGS_RESET_SUCCESS' })
      }
    } catch (e) {
      console.log(e)
    }
  },
  *LOGS_ADD_LOGS_SESSION({ action }) {
    const userAuth = yield call(getCurrentUser);
    const userSnapshot = yield call(rsFirebase.firestore.getDocument, `/users/${userAuth.uid}/`)
    const user = userSnapshot.data()

    const countSnapshot = yield call(rsFirebase.firestore.getCollection, `logs/session/logs`);
    const size = countSnapshot.size;

    const data = {
      id: size + 1,
      userId: user.id,
      userEmail: user.email,
      userLastName: user.lastName,
      userFirstName: user.firstName,
      date: Date.now(),
      action: action,
    }

    yield call(rsFirebase.firestore.setDocument, `logs/session/logs/${size+1}`, data);
    yield put({ type: 'LOGS_ADD_LOGS_SUCCESS' })
  },
  *LOGS_REQUEST_LIST({ appId }) {
    try {
      yield put({ type: 'LOGS_FETCHING' });
      const logsListSnap = yield call(rsFirebase.firestore.getCollection, `logs/${appId}/logs`);
      const logsListArray = [];
      logsListSnap.docs.forEach((log) => {
        logsListArray.push(log.data());
      });
      logsListArray.sort((a, b) => b.id - a.id);
      yield put({ type: 'LOGS_SET', list: logsListArray });
    } catch (error) {
      yield put({ type: 'LOGS_FETCHING_ERROR' });
    }
  },
  *LOGS_REQUEST_SESSIONS_LIST() {
    try {
      const logsListSnap = yield call(rsFirebase.firestore.getCollection, `logs/session/logs`);
      const logsListArray = [];
      logsListSnap.docs.forEach((log) => {
        logsListArray.push(log.data());
      });
      logsListArray.sort((a, b) => b.id - a.id);
      yield put({ type: 'LOGS_SESSION_SET', sessions: logsListArray });
    } catch (error) {
      yield put({ type: 'LOGS_SESSION_ERROR' });
    }
  },
})