import { defineMessages } from 'react-intl'

const scope = 'app.components.FolderModal'

export default defineMessages({
  cancel: { id: `${scope}.cancel` },
  colors: { id: `${scope}.colors` },
  colorsPlaceholder: { id: `${scope}.colorsPlaceholder` },
  createFolder: { id: `${scope}.createFolder` },
  description: { id: `${scope}.description` },
  descriptionPlaceholder: { id: `${scope}.descriptionPlaceholder` },
  image: { id: `${scope}.image` },
  name: { id: `${scope}.name` },
  nameError: { id: `${scope}.nameError` },
  namePlaceholder: { id: `${scope}.namePlaceholder` },
  newFolder: { id: `${scope}.newFolder` },
  note: { id: `${scope}.note` },
  notePlaceholder: { id: `${scope}.notePlaceholder` },
  required: { id: `${scope}.required` },
  save: { id: `${scope}.save` },
  linkLogo: { id: `${scope}.linkLogo` },
  linkLogoError: { id: `${scope}.linkLogoError` },
  linkLogoInfo: { id: `${scope}.linkLogoInfo` },
  blankLinkLogo: { id: `${scope}.blankLinkLogo` },
  warning: { id: `${scope}.warning` },
  faviconHelpMessage: { id: `${scope}.faviconHelpMessage` }
})
