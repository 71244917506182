import { Switch, Route, Redirect } from 'react-router-dom'
import RTE from 'utils/routes'
import { StatusCheck } from 'components'

import PageDashboard from 'containers/PageDashboard/Loadable'
import PageFeedbacks from 'containers/PageFeedbacks'
import PageData from 'containers/PageData'
import PageLogin from 'containers/PageLogin/Loadable'
import PageLogout from 'containers/PageLogout/Loadable'
import PageCards from 'containers/PageCards/Loadable'
import PageManageApps from 'containers/PageManageApps/Loadable'
import PageManageUsers from 'containers/PageManageUsers/Loadable'
import PageTutorial from 'containers/PageTutorial/Loadable'
import PageVoxpop from 'containers/PageVoxpop/Loadable'
import PageVoxpopFiles from 'containers/PageVoxpopFiles/Loadable'
import PageContact from 'containers/PageContact/Loadable'
import PageSettings from 'containers/PageSettings/Loadable'
import PageFolders from 'containers/PageFolders/Loadable'

import {
  PageCard,
  PageCardEdit,
  PageCardNew,
  PageInputs,
  PageItems,
  PageLists,
  PageLocks,
  PageRandomStory,
  PageNotFound,
  PagePhotobooths,
  PageTriggers,
} from 'containers'

import ScrollToTop from 'utils/ScrollToTop'
import PrivateRoute from './PrivateRoute'
import PageLogs from "../PageLogs";

const App = () => (
  <ScrollToTop>
    <Switch>
      <Redirect exact from="/" to={RTE.LOGIN.path} />

      <Route exact path={RTE.LOGIN.path} component={PageLogin} />
      <Route exact path={RTE.INIT_PASSWORD.path} component={PageLogin} />
      <Route exact path={RTE.LOST_PASSWORD.path} component={PageLogin} />
      <Route exact path={RTE.RESET_PASSWORD.path} component={PageLogin} />
      <Route exact path={RTE.LOGOUT.path} component={PageLogout} />

      <PrivateRoute exact path={RTE.HOME.path} component={PageDashboard} destination="PageDashboard"/>
      <PrivateRoute exact path={RTE.FEEDBACKS.path} component={PageFeedbacks} destination="PageFeedbacks"/>
      <PrivateRoute exact path={RTE.DATA.path} component={PageData} destination="PageData"/>
      <PrivateRoute exact path={RTE.LOGS.path} component={PageLogs} destination="PageLogs" />

      <PrivateRoute exact path={RTE.INPUTS.path} component={PageInputs} destination="PageInputs"/>
      <PrivateRoute exact path={RTE.INPUTS_SINGLE.path} component={PageInputs} destination="PageInputs"/>
      <PrivateRoute exact path={RTE.ITEMS.path} component={PageItems} destination="PageItems"/>
      <PrivateRoute exact path={RTE.ITEMS_SINGLE.path} component={PageItems} destination="PageItems"/>
      <PrivateRoute exact path={RTE.LISTS.path} component={PageLists} destination="PageLists"/>
      <PrivateRoute exact path={RTE.LISTS_SINGLE.path} component={PageLists} destination="PageLists"/>
      <PrivateRoute exact path={RTE.LOCKS.path} component={PageLocks} destination="PageLocks"/>
      <PrivateRoute exact path={RTE.LOCKS_SINGLE.path} component={PageLocks} destination="PageLocks"/>
      <PrivateRoute exact path={RTE.RANDOM_STORY.path} component={PageRandomStory} destination="PageRandomStory" />
      <PrivateRoute exact path={RTE.RANDOM_STORY_SINGLE.path} component={PageRandomStory} destination="PageRandomStory" />
      <PrivateRoute exact path={RTE.TRIGGERS.path} component={PageTriggers} destination="PageTriggers"/>
      <PrivateRoute exact path={RTE.TRIGGERS_SINGLE.path} component={PageTriggers} destination="PageTriggers"/>
      <PrivateRoute exact path={RTE.PHOTOBOOTHS.path} component={PagePhotobooths} destination="PagePhotobooths"/>
      <PrivateRoute exact path={RTE.PHOTOBOOTHS_SINGLE.path} component={PagePhotobooths} destination="PagePhotobooths"/>

      <PrivateRoute exact path={RTE.TUTORIAL.path} component={PageTutorial} destination="PageTutorial"/>

      <PrivateRoute exact path={RTE.VOXPOP.path} component={PageVoxpop} destination="PageVoxpop"/>
      <PrivateRoute exact path={RTE.VOXPOPFILES.path} component={PageVoxpopFiles} destination="PageVoxpopFiles"/>

      <PrivateRoute exact path={RTE.MANAGEAPPS.path} component={PageManageApps} destination="PageManageApps"/>
      <PrivateRoute exact path={RTE.MANAGEAPPS_SINGLE.path} component={PageManageApps} destination="PageManageApps"/>
      <PrivateRoute exact path={RTE.MANAGEUSERS.path} component={PageManageUsers} destination="PageManageUsers"/>
      <PrivateRoute exact path={RTE.MANAGEUSERS_SINGLE.path} component={PageManageUsers} destination="PageManageUsers"/>

      <PrivateRoute exact path={RTE.SETTINGS.path} component={PageSettings} destination="PageSettings"/>

      <Redirect from="/:appId/cards" to="/:appId/pages/all" />
      <Redirect from="/:appId/card" to="/:appId/pages/all" />
      <Redirect exact from="/:appId/page" to="/:appId/pages/all" />
      <Redirect exact from="/:appId/pages" to="/:appId/pages/all" />

      <PrivateRoute exact path={RTE.CARDS_TAG.path} component={PageCards} destination="PageCards"/>

      <PrivateRoute exact path={RTE.FOLDERS.path} component={PageFolders} destination="PageFolders"/>
      <PrivateRoute exact path={RTE.FOLDER.path} component={PageFolders} destination="PageFolders"/>
      <PrivateRoute exact path={RTE.FOLDER_TAG.path} component={PageFolders} destination="PageFolders"/>

      <PrivateRoute exact path={RTE.ONE_CARD.path} component={PageCard} destination="PageCard"/>
      <PrivateRoute exact path={RTE.ONE_CARD_EDIT.path} component={PageCardEdit} destination="PageCardEdit"/>
      <PrivateRoute exact path={RTE.ONE_CARD_NEW.path} component={PageCardNew} destination="PageCardNew"/>

      <PrivateRoute exact path={RTE.CONTACT.path} component={PageContact} destination="PageContact"/>

      <Route exact path="/api/health" component={StatusCheck} />

      <Route path={RTE.NOTFOUND.path} component={PageNotFound} />
      <Redirect from="*" to={RTE.NOTFOUND.path} />
    </Switch>
  </ScrollToTop>
)

export default App
