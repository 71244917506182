import styled, {css} from 'styled-components'
import { Icon } from 'react-icons-kit'

export const StyledCard = styled.div`
  border-radius: 8px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  background-color: white;
  padding: 23px 40px;

  p {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &:not(:last-of-type) {
    margin-bottom: 20px;
  }
`

export const StyledIcon = styled(Icon)`
  color: #ffd232;
  display: flex !important;
  margin-right: 10px;

  &:last-of-type {
    margin-left: 10px;
    margin-right: 0;
  }
`

export const StyledSmallLegend = styled.span`
  color: #89959f;
  font-family: Nunito,serif;
  font-size: 12px;
  font-weight: 600;
  line-height: 33px;
  text-transform: uppercase;
`

export const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
`;

export const StyledTableCounter = styled.table`
  border-collapse: collapse;
  margin-top: 20px;
  width: 20%;
  margin-left: 5px;
  margin-right: 5px;
`;

export const StyledThead = styled.thead`
  border-bottom: 1px solid #ababab;
`;

export const StyledCounterThead = styled.thead`
  border-bottom: 1px solid #ababab;
  color: #416db2;
`;

export const StyledTr = styled.tr`
  &:nth-child(even) {
    background-color: #f2f2f2;
  }
`;

export const StyledCounterTr = styled.tr`
  max-height: 42px;
  
  &:nth-child(even) {
    background-color: #f2f2f2;
  }
`;

export const StyledTh = styled.td`
  font-family: Nunito, serif;
  padding: 12px 15px;
  text-align: left;
  font-weight: bold;
  color: #333;
`;

export const StyledTd = styled.td`
  font-family: Nunito, serif;
  padding: 10px 15px;
  text-align: left;
  border-bottom: 1px solid #ddd;
`;

export const StyledCounterPartTd = styled.td`
  font-family: Nunito, serif;
  text-align: left;
  border-bottom: 1px solid #ddd;
  font-weight: bold;
`;

export const StyledCounterPartValueTd = styled.td`
  text-align: center;
  border-bottom: 1px solid #ddd;
`;

export const StyledCounterHeadTd = styled.td`
  font-family: Nunito, serif;
  padding: 10px 15px;
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
`;

export const StyledContainer = styled.div`
  position: relative;
  display: flex;

  @media (max-width: 576px) {
    width: 100%;
  }
`;

export const StyledElement = styled.div`
  margin: 5px;
  p {
    font-weight: bold;
    font-family: Nunito, serif;
    margin: 0;
  }
`;

export const StyledSelect = styled.select`
  height: 40px;
  border-radius: 8px !important;
    border: 1px solid #d9d9d9;
  font-size: 15px;
  width: 200px;
`;

export const StyledInput = styled.input`
  height: 40px;
  border-radius: 8px !important;
  border: 1px solid #d9d9d9;
  padding: 13px;
  font-size: 15px;
  width: 200px;

  @media (max-width: 576px) {
    width: 100%;
  }

  &::placeholder {
    color: #bebebe;
  }
`

export const StyledCollapse = styled.div`
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #ddd;
  margin-top: -23px;
  cursor: pointer;

  i {
    color: #416db2;
    cursor: pointer;
  }

  legend {
    cursor: pointer;
  }
`

export const StyledLegend = styled.legend`
  text-transform: uppercase;
  font-weight: 600 !important;
  font-size: 14px !important;
  color: #406cb3;
  margin-top: 0 !important;
`

export const StyledCollapseDiv = styled.div`
    margin-bottom: 10px;
  ${({ open }) =>
  !open &&
  css`
      height: 0;
      visibility: hidden;
      margin-top: 10px;

      .form-group {
        margin: 0;
      }
    `}
`