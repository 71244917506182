import PropTypes from 'prop-types'
import { useState } from 'react'
import { useIntl } from 'react-intl'
import {
  BasicModal,
  BasicButton,
  BasicInput,
  BasicForm,
  BasicTextArea,
  BasicLabel,
  ImgDropzone, BasicWarning
} from "components";
import { HEX_COLORS } from 'utils/consts'
import Circle from '@uiw/react-color-circle'

import { connectStore } from 'redux-box'
import { storeModule as AppModule } from 'store/app'

import messages from './messages'
import { validationsPatterns } from "../../utils";
import BasicCheckbox from "../BasicCheckbox";
import styled from 'styled-components'
import BasicImageCrop from '../BasicImageCrop'
import {storeModule as ManageUsersModule} from "../../store/manageUsers";

// to handle color values before hex were implemented
const getColor = (color) => {
  switch (color) {
    case 'pink':
      return '#EF456F'
    case 'grey':
      return '#88959F'
    case 'green':
      return '#3DB076'
    case 'blue':
      return '#3F6CB3'
    case 'yellow':
      return '#FFD232'
    case 'orange':
      return '#F59E33'
    default:
      return color
  }
}

const StyledInfo = styled.div`
    border-radius: 100%;
    width: 16px;
    height: 16px;
    border: 1px solid rgb(123, 156, 208);
    line-height: 14px;
    text-align: center;
    font-size: 12px;
    font-weight: bold;
    color: rgb(123, 156, 208);
    margin-left: 5px;
    cursor: pointer;
  `

const FolderModal = ({ app, cardId, data, isOpen, toggleModal, manageUsers }) => {
  const [hex, setHex] = useState(getColor(data.color) || '#EF456F')
  const [image, setImage] = useState((data && data.file) || (data && data.url) || null)

  const intl = useIntl()

  const handleOnSubmit = (e, values) => {
    e.preventDefault()
    app.saveFolder(app.id, { ...values, name: values.name.trim(), color: hex, file: image || '' }, cardId)
    manageUsers.updateUserRights(app.id, manageUsers.profile, {
      type: 'folders',
      name: values.name.trim()
    });
    toggleModal(e)
  }

  const handleDropZoneChange = (acceptedFiles) => {
    if (acceptedFiles.length) setImage(acceptedFiles[0])
  }

  const handleRemovePicture = () => setImage(null)

  return (
    <>
      {isOpen && (
        <BasicModal
          title={data && data.name ? data.name : intl.formatMessage(messages.newFolder)}
          toggle={toggleModal}
          isOpen={isOpen}
          content={
            <BasicForm onValidSubmit={handleOnSubmit} model={{ ...data, color: hex }}>
              <BasicInput name="id" type="hidden" />

              <BasicInput
                label={intl.formatMessage(messages.name)}
                name="name"
                placeholder={intl.formatMessage(messages.namePlaceholder)}
                error={intl.formatMessage(messages.nameError)}
                required
              />

              <div>
                <BasicLabel label={intl.formatMessage(messages.colors)} />
                <Circle color={hex} onChange={(color) => setHex(color.hex)} colors={HEX_COLORS} />
              </div>

              {app.access.Favicon && (
                <BasicImageCrop
                  required={false}
                  name="favicon"
                  label="Fav icon"
                  helpMessage={intl.formatMessage(messages.faviconHelpMessage)}
                  file={data.favicon}
                />
              )}

              <BasicInput
                label={
                  <span style={{display:'inline-flex'}}>
                    {intl.formatMessage(messages.linkLogo)}
                    <StyledInfo title={intl.formatMessage(messages.linkLogoInfo)}>?</StyledInfo>
                  </span>
                }
                type="text"
                name="linkLogo"
                error={intl.formatMessage(messages.linkLogoError)}
                validation={{
                  pattern: validationsPatterns.urlExtended,
                }}
              />
              <BasicCheckbox
                label={intl.formatMessage(messages.blankLinkLogo)}
                name="openBlankLinkLogo"
              />
              <BasicWarning text={intl.formatMessage(messages.warning)} />

              <BasicInput
                label={intl.formatMessage(messages.note)}
                name="note"
                placeholder={intl.formatMessage(messages.notePlaceholder)}
              />

              <BasicTextArea
                label={intl.formatMessage(messages.description)}
                name="description"
                placeholder={intl.formatMessage(messages.descriptionPlaceholder)}
                bsSize="small"
              />

              <ImgDropzone
                name="file"
                label={intl.formatMessage(messages.image)}
                file={image}
                url={image}
                onChange={handleDropZoneChange}
                onDelete={handleRemovePicture}
              />

              <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end' }}>
                <BasicButton type="submit" label={intl.formatMessage(messages.save)} color="green" />
              </div>
            </BasicForm>
          }
        />
      )}
    </>
  )
}

FolderModal.defaultProps = {
  data: { name: '', description: '', color: '#EF456F', note: '', image: {} },
  isOpen: false,
  toggleModal: () => {},
}

FolderModal.propTypes = {
  app: PropTypes.object.isRequired,
  manageUsers: PropTypes.object,
  cardId: PropTypes.string,
  data: PropTypes.object,
  isOpen: PropTypes.bool,
  toggleModal: PropTypes.func,
}

export default connectStore({ app: AppModule, manageUsers: ManageUsersModule })(FolderModal)
