import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { useIntl } from 'react-intl'
import { PageTitle } from 'components'
import { MainAppContainer } from 'containers'

import {
  StyledCard,
  StyledSmallLegend,
  StyledCollapse, StyledCollapseDiv, StyledLegend,
} from './styles'
import messages from './messages'
import {connectStore} from "redux-box";
import {storeModule as LogsModule} from "../../store/logs";
import {useEffect, useState} from "react";
import SearchLogs from "./search";
import {useSearchLogs} from "../../hooks/useSearchLogs";
import {storeModule as cardListModule} from "../../store/cardList";
import Counter from "./counter";

import { Icon } from 'react-icons-kit'
import { chevronUp } from 'react-icons-kit/feather/chevronUp'
import { chevronDown } from 'react-icons-kit/feather/chevronDown'
import LogsContent from './logsContent'
import LogsSession from './logsSession'
import SearchSessions from './searchSessions'
import { useSearchLogsSessions } from '../../hooks/useSearchLogsSessions'

const PageLogs = ({
  match: {
    params: { appId },
  },
  logs,
  cardList,
}) => {
  const intl = useIntl()
  const [open, setOpen] = useState(false)
  const [sessionOpen, setSessionOpen] = useState(false)
  const [logsOpen, setLogsOpen] = useState(true)

  useEffect(() => {
    logs.getLogs(appId);
    logs.getLogsSessions();
  }, [logs]);

  const { filteredLogsList, setSearchLogsQuery, searchLogsQuery } = useSearchLogs({
    list: logs.list,
  })
  const { filteredSessionsList, setSearchSessionsQuery, searchSessionsQuery } = useSearchLogsSessions({
    sessions: logs.sessions,
  })

  const isFilterLogsApplied = () => {
    return (
      searchLogsQuery.user !== '' ||
      searchLogsQuery.content !== '' ||
      searchLogsQuery.action  !== ''||
      searchLogsQuery.where !== ''||
      (searchLogsQuery.dateRange[0] !== null && searchLogsQuery.dateRange[1] !== null)
    )
  }

  const isFilterSessionsApplied = () => {
    return (
      searchSessionsQuery.user !== '' ||
      searchSessionsQuery.action  !== ''||
      (searchSessionsQuery.dateRange[0] !== null && searchSessionsQuery.dateRange[1] !== null)
    )
  }

  const displayLogsList = isFilterLogsApplied() ? filteredLogsList : logs.list
  const displaySessionsList = isFilterSessionsApplied() ? filteredSessionsList : logs.sessions

  return (
    <>
      <Helmet title={intl.formatMessage(messages.metaTitle)} />

      <MainAppContainer appId={appId}>
        <PageTitle>{intl.formatMessage(messages.logs)}</PageTitle>

        <StyledSmallLegend>{intl.formatMessage(messages.actions)}</StyledSmallLegend>
        <StyledCollapse onClick={() => setOpen(!open)}>
          <StyledLegend collapsable="true" />
          <Icon icon={open ? chevronUp : chevronDown} />
        </StyledCollapse>
        <StyledCollapseDiv open={open}>
          <StyledCard style={{marginTop: '10px'}}>
            {cardList.list.length > 0 && <Counter list={cardList.list} />}
          </StyledCard>
        </StyledCollapseDiv>

        <StyledSmallLegend>{intl.formatMessage(messages.sessions)}</StyledSmallLegend>
        <StyledCollapse onClick={() => setSessionOpen(!sessionOpen)}>
          <StyledLegend collapsable="true" />
          <Icon icon={sessionOpen ? chevronUp : chevronDown} />
        </StyledCollapse>
        <StyledCollapseDiv open={sessionOpen}>
          <StyledCard style={{marginTop: '10px'}}>
           <SearchSessions
              searchQuery={searchSessionsQuery}
              setSearchQuery={setSearchSessionsQuery}
            />
            <LogsSession list={displaySessionsList} />
          </StyledCard>
        </StyledCollapseDiv>

        <StyledSmallLegend>{intl.formatMessage(messages.logs)}</StyledSmallLegend>
        <StyledCollapse onClick={() => setLogsOpen(!logsOpen)}>
          <StyledLegend collapsable="true" />
          <Icon icon={logsOpen ? chevronUp : chevronDown} />
        </StyledCollapse>
        <StyledCollapseDiv open={logsOpen}>
          <StyledCard style={{marginTop: '10px'}}>
            <SearchLogs
              searchQuery={searchLogsQuery}
              setSearchQuery={setSearchLogsQuery}
            />
            <LogsContent list={displayLogsList}/>
          </StyledCard>
        </StyledCollapseDiv>

      </MainAppContainer>
    </>
  )
}

PageLogs.propTypes = {
  match: PropTypes.object,
  logs: PropTypes.object,
  cardList: PropTypes.object,
}

export default connectStore({ logs: LogsModule, cardList: cardListModule })(PageLogs)

