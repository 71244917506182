import { defineMessages } from 'react-intl'

const scope = 'app.containers.PageLogs'
const scope2 = 'app.components.PartTypeForm'

export default defineMessages({
    metaTitle: { id: `${scope}.metaTitle` },
    logs: { id: `${scope}.logs` },
    actions: { id: `${scope}.actions` },
    sessions: { id: `${scope}.sessions` },
    user: { id: `${scope}.user` },
    date: { id: `${scope}.date` },
    link: { id: `${scope}.link` },
    content: { id: `${scope}.content` },
    action: { id: `${scope}.action` },
    filterEmail: { id: `${scope}.filterEmail` },
    filterContent: { id: `${scope}.filterContent` },
    filterAction: { id: `${scope}.filterAction` },
    filterUrl: { id: `${scope}.filterUrl` },
    filterPeriod: { id: `${scope}.filterPeriod` },
    loading: { id: `${scope}.loading` },

    sectionText: { id: `${scope2}.sectionText` },
    text: { id: `${scope2}.text` },
    subtitle: { id: `${scope2}.subtitle` },
    divider: { id: `${scope2}.divider` },
    sectionImage: { id: `${scope2}.sectionImage` },
    pictureUpload: { id: `${scope2}.pictureUpload` },
    picture: { id: `${scope2}.picture` },
    giphy: { id: `${scope2}.giphy` },
    sketchFab: { id: `${scope2}.sketchFab` },
    sectionAudio: { id: `${scope2}.sectionAudio` },
    audio: { id: `${scope2}.audio` },
    soundCloud: { id: `${scope2}.soundCloud` },
    sectionVideo: { id: `${scope2}.sectionVideo` },
    video: { id: `${scope2}.video` },
    youtube: { id: `${scope2}.youtube` },
    vimeo: { id: `${scope2}.vimeo` },
    sectionButton: { id: `${scope2}.sectionButton` },
    button: { id: `${scope2}.button` },
    halfButton: { id: `${scope2}.halfButton` },
    facebookButton: { id: `${scope2}.facebookButton` },
    unityButton: { id: `${scope2}.unityButton` },
    languageLink: { id: `${scope2}.languageLink` },
    sectionIntegration: { id: `${scope2}.sectionIntegration` },
    googleMaps: { id: `${scope2}.googleMaps` },
    googleForm: { id: `${scope2}.googleForm` },
    sectionRedirect: { id: `${scope2}.sectionRedirect` },
    redirect: { id: `${scope2}.redirect` },
    randomRedirect: { id: `${scope2}.randomRedirect` },
    randomReset: { id: `${scope2}.randomReset` },
    randomStory: { id: `${scope2}.randomStory` },
    randomStoryReset: { id: `${scope2}.randomStoryReset` },
    sectionLock: { id: `${scope2}.sectionLock` },
    lock: { id: `${scope2}.lock` },
    lockReset: { id: `${scope2}.lockReset` },
    lockPage: { id: `${scope2}.lockPage` },
    sectionPremium: { id: `${scope2}.sectionPremium` },
    voxpop: { id: `${scope2}.voxpop` },
    photobooth: { id: `${scope2}.photobooth` },
    list: { id: `${scope2}.list` },
    sectionModule: { id: `${scope2}.sectionModule` },
    trigger: { id: `${scope2}.trigger` },
    product: { id: `${scope2}.product` },
    input: { id: `${scope2}.input` },
})
