const actions = {
  deleteCard: (cardId, appId) => ({ type: 'CARD_DELETE', cardId, appId }),
  deleteCardContent: (cardId, appId) => ({ type: 'CARD_DELETE_CONTENT', cardId, appId }),
  duplicateCard: (cardId, appId) => ({type: 'CARD_DUPLICATE_CARD', cardId, appId }),
  getCardById: (cardId, appId) => ({ type: 'CARD_FETCH_BY_ID', cardId, appId }),
  resetCardStatus: () => ({ type: 'CARD_RESET_STATUS' }),
  resetState: () => ({ type: 'CARD_RESET_STATE' }),
  saveCard: (data, app, isNew) => ({ type: 'CARD_SAVE', data, app, isNew }),
  saveCardFolder: (data, appId) => ({ type: 'CARD_SAVE_FOLDER', data, appId }),
  updateCard: (data, appId) => ({ type: 'CARD_UPDATE', data, appId }),
  setCardInfo: (card) => ({ type: 'CARD_SET_CONTENT', card }),
  setFolders: (folderIds) => ({ type: 'CARD_SET_FOLDERS', folderIds }),
}
export default actions
