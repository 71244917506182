import { useState, useEffect } from 'react'
import moment from 'moment'

export const useSearchLogs = ({ list }) => {
  const [searchLogsQuery, setSearchLogsQuery] = useState({ user: '', content: '', action: '', where: '', dateRange: [null, null], })
  const [filteredLogsList, setFilteredLogsList] = useState(list)

  useEffect(() => {
    const filterList = () => {
      let filteredList = list
      if (searchLogsQuery.user !== '') {
        filteredList = filteredList.filter(item => item.userEmail.includes(searchLogsQuery.user))
      }
      if (searchLogsQuery.content !== '') {
        filteredList = filteredList.filter(item => item.content.includes(searchLogsQuery.content))
      }
      if (searchLogsQuery.action !== '') {
        filteredList = filteredList.filter(item => item.action === searchLogsQuery.action)
      }
      if (searchLogsQuery.where !== '') {
        filteredList = filteredList.filter(item => item.where.includes(searchLogsQuery.where))
      }
      if (searchLogsQuery.dateRange[0] && searchLogsQuery.dateRange[1]) {
        const [start, end] = searchLogsQuery.dateRange
        filteredList = filteredList.filter((item) =>
          moment(item.date).isSameOrAfter(start) && moment(item.date).isSameOrBefore(end)
        )
      }
      return filteredList
    }

    setFilteredLogsList(filterList())
  }, [list, searchLogsQuery])

  return {
    filteredLogsList,
    setSearchLogsQuery,
    searchLogsQuery,
  }
}