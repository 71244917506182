import { useState, useEffect } from 'react'
import moment from 'moment'

export const useSearchLogsSessions = ({ sessions }) => {
  const [searchSessionsQuery, setSearchSessionsQuery] = useState({ user: '', action: '', dateRange: [null, null], })
  const [filteredSessionsList, setFilteredSessionsList] = useState(sessions)

  useEffect(() => {
    const filterList = () => {
      let filteredList = sessions
      if (searchSessionsQuery.user !== '') {
        filteredList = filteredList.filter(item => item.userEmail.includes(searchSessionsQuery.user))
      }
      if (searchSessionsQuery.action !== '') {
        filteredList = filteredList.filter(item => item.action === searchSessionsQuery.action)
      }
      if (searchSessionsQuery.dateRange[0] && searchSessionsQuery.dateRange[1]) {
        const [start, end] = searchSessionsQuery.dateRange
        filteredList = filteredList.filter((item) =>
          moment(item.date).isSameOrAfter(start) && moment(item.date).isSameOrBefore(end)
        )
      }
      return filteredList
    }

    setFilteredSessionsList(filterList())
  }, [sessions, searchSessionsQuery])

  return {
    filteredSessionsList,
    setSearchSessionsQuery,
    searchSessionsQuery,
  }
}