import { defineMessages } from 'react-intl'

const scope = 'app.components.NavBar'

export default defineMessages({
  all: { id: `${scope}.all` },
  cards: { id: `${scope}.cards` },
  changePassword: { id: `${scope}.changePassword` },
  contact: { id: `${scope}.contact` },
  data: { id: `${scope}.data` },
  en: { id: `${scope}.en` },
  feedbacks: { id: `${scope}.feedbacks` },
  fr: { id: `${scope}.fr` },
  home: { id: `${scope}.home` },
  input: { id: `${scope}.input` },
  item: { id: `${scope}.item` },
  linked: { id: `${scope}.linked` },
  lists: { id: `${scope}.lists` },
  locks: { id: `${scope}.locks` },
  logs: { id: `${scope}.logs` },
  randomStory: { id: `${scope}.randomStory` },
  logout: { id: `${scope}.logout` },
  manage: { id: `${scope}.manage` },
  manageapps: { id: `${scope}.manageapps` },
  manageusers: { id: `${scope}.manageusers` },
  modules: { id: `${scope}.modules` },
  photobooth: { id: `${scope}.photobooth` },
  profile: { id: `${scope}.profile` },
  settings: { id: `${scope}.settings` },
  tutorial: { id: `${scope}.tutorial` },
  trigger: { id: `${scope}.trigger` },
  unlinked: { id: `${scope}.unlinked` },
  voxpop: { id: `${scope}.voxpop` },
  welcome: { id: `${scope}.welcome` },
  yourAccount: { id: `${scope}.yourAccount` },
})
