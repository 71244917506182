import 'moment/locale/en-ca'
import 'moment/locale/fr-ca'
import localeEn from 'antd/es/date-picker/locale/en_US'
import localeFr from 'antd/es/date-picker/locale/fr_FR'
import { DatePicker } from 'antd'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'

const DateFilter = ({ value, onChange }) => {
  const intl = useIntl()

  const handleDateChange = (dates) => {
    if (dates) {
      const adjustedDates = [
        dates[0] ? dates[0].startOf('day') : null,
        dates[1] ? dates[1].endOf('day') : null,
      ]
      onChange(adjustedDates)
    } else {
      onChange([null, null])
    }
  }

  return (
    <DatePicker.RangePicker
      style={{ width: '100%', borderRadius: '8px' }}
      locale={intl.locale === 'fr' ? localeFr : localeEn}
      onChange={handleDateChange}
      value={value}
      size="large"
    />
  )
}

DateFilter.propTypes = {
  value: PropTypes.array,
  onChange: PropTypes.func,
}

export default DateFilter