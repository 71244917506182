/**
 * BasicFormSection Component
 *
 * This component renders a form section that can be either collapsible or static.
 * If collapsible, it toggles between open and closed states based on user interaction,
 * displaying the label and an icon to indicate the current state. Otherwise, it displays
 * a static section with the provided content.
 */

import { useState } from 'react'
import PropTypes from 'prop-types'

import { Icon } from 'react-icons-kit'
import { StyledLegend, StyledCollapse, StyledCollapseDiv } from './styles'

import { chevronUp } from 'react-icons-kit/feather/chevronUp'
import { chevronDown } from 'react-icons-kit/feather/chevronDown'

const BasicCollapsibleSection = ({ label, children, ...props }) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
      <StyledCollapse onClick={() => setIsOpen(!isOpen)}>
        <StyledLegend collapsable="true">{label}</StyledLegend>
        <Icon icon={isOpen ? chevronUp : chevronDown} />
      </StyledCollapse>

      <StyledCollapseDiv open={isOpen}>
        {children}
      </StyledCollapseDiv>
    </>
  )
}

BasicCollapsibleSection.defaultProps = {
};

BasicCollapsibleSection.propTypes = {
  label: PropTypes.string,
  children: PropTypes.node,
  props: PropTypes.object
}

export default BasicCollapsibleSection
