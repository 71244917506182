import PropTypes from 'prop-types'
import {useCountModules} from "../../hooks/useCountModules";
import {
  StyledCounterThead,
  StyledCounterTr,
  StyledCounterHeadTd,
  StyledCounterPartTd,
  StyledCounterPartValueTd,
  StyledTableCounter
} from './styles'
import messages from './messages'
import { useIntl } from 'react-intl'

const Counter = ({ list }) => {

  const counter = useCountModules(list);
  const intl = useIntl();

  return (
    <>
      <div style={{display:'flex'}}>
        <StyledTableCounter>
          <StyledCounterThead>
            <StyledCounterTr>
              <StyledCounterHeadTd colSpan={2}>{intl.formatMessage(messages.sectionText)}</StyledCounterHeadTd>
            </StyledCounterTr>
          </StyledCounterThead>
          <tbody>
            <StyledCounterTr>
              <StyledCounterPartTd>{intl.formatMessage(messages.subtitle)}</StyledCounterPartTd>
              <StyledCounterPartValueTd>{counter.h2}</StyledCounterPartValueTd>
            </StyledCounterTr>
            <StyledCounterTr>
              <StyledCounterPartTd>{intl.formatMessage(messages.text)}</StyledCounterPartTd>
              <StyledCounterPartValueTd>{counter.text}</StyledCounterPartValueTd>
            </StyledCounterTr>
            <StyledCounterTr>
              <StyledCounterPartTd>{intl.formatMessage(messages.divider)}</StyledCounterPartTd>
              <StyledCounterPartValueTd>{counter.separator}</StyledCounterPartValueTd>
            </StyledCounterTr>
          </tbody>
        </StyledTableCounter>
        <StyledTableCounter>
          <StyledCounterThead>
            <StyledCounterTr>
              <StyledCounterHeadTd colSpan={2}>{intl.formatMessage(messages.sectionImage)}</StyledCounterHeadTd>
            </StyledCounterTr>
          </StyledCounterThead>
          <tbody>
            <StyledCounterTr>
              <StyledCounterPartTd>{intl.formatMessage(messages.pictureUpload)}</StyledCounterPartTd>
              <StyledCounterPartValueTd>{counter.picture}</StyledCounterPartValueTd>
            </StyledCounterTr>
            <StyledCounterTr>
              <StyledCounterPartTd>{intl.formatMessage(messages.picture)}</StyledCounterPartTd>
              <StyledCounterPartValueTd>{counter.pictureUrl}</StyledCounterPartValueTd>
            </StyledCounterTr>
            <StyledCounterTr>
              <StyledCounterPartTd>{intl.formatMessage(messages.giphy)}</StyledCounterPartTd>
              <StyledCounterPartValueTd>{counter.giphy}</StyledCounterPartValueTd>
            </StyledCounterTr>
            <StyledCounterTr>
              <StyledCounterPartTd>{intl.formatMessage(messages.sketchFab)}</StyledCounterPartTd>
              <StyledCounterPartValueTd>{counter.sketchFab}</StyledCounterPartValueTd>
            </StyledCounterTr>
          </tbody>
        </StyledTableCounter>
        <StyledTableCounter>
          <StyledCounterThead>
            <StyledCounterTr>
              <StyledCounterHeadTd colSpan={2}>{intl.formatMessage(messages.sectionAudio)}</StyledCounterHeadTd>
            </StyledCounterTr>
          </StyledCounterThead>
          <tbody>
            <StyledCounterTr>
              <StyledCounterPartTd>{intl.formatMessage(messages.audio)}</StyledCounterPartTd>
              <StyledCounterPartValueTd>{counter.audio}</StyledCounterPartValueTd>
            </StyledCounterTr>
            <StyledCounterTr>
              <StyledCounterPartTd>{intl.formatMessage(messages.soundCloud)}</StyledCounterPartTd>
              <StyledCounterPartValueTd>{counter.soundCloud}</StyledCounterPartValueTd>
            </StyledCounterTr>
          </tbody>
        </StyledTableCounter>
        <StyledTableCounter>
          <StyledCounterThead>
            <StyledCounterTr>
              <StyledCounterHeadTd colSpan={2}>{intl.formatMessage(messages.sectionVideo)}</StyledCounterHeadTd>
            </StyledCounterTr>
          </StyledCounterThead>
          <tbody>
            <StyledCounterTr>
              <StyledCounterPartTd>{intl.formatMessage(messages.video)}</StyledCounterPartTd>
              <StyledCounterPartValueTd>{counter.video}</StyledCounterPartValueTd>
            </StyledCounterTr>
            <StyledCounterTr>
              <StyledCounterPartTd>{intl.formatMessage(messages.youtube)}</StyledCounterPartTd>
              <StyledCounterPartValueTd>{counter.videoYoutube}</StyledCounterPartValueTd>
            </StyledCounterTr>
            <StyledCounterTr>
              <StyledCounterPartTd>{intl.formatMessage(messages.vimeo)}</StyledCounterPartTd>
              <StyledCounterPartValueTd>{counter.vimeo}</StyledCounterPartValueTd>
            </StyledCounterTr>
          </tbody>
        </StyledTableCounter>
        <StyledTableCounter>
          <StyledCounterThead>
            <StyledCounterTr>
              <StyledCounterHeadTd colSpan={2}>{intl.formatMessage(messages.sectionButton)}</StyledCounterHeadTd>
            </StyledCounterTr>
          </StyledCounterThead>
          <tbody>
            <StyledCounterTr>
              <StyledCounterPartTd>{intl.formatMessage(messages.button)}</StyledCounterPartTd>
              <StyledCounterPartValueTd>{counter.button}</StyledCounterPartValueTd>
            </StyledCounterTr>
            <StyledCounterTr>
              <StyledCounterPartTd>{intl.formatMessage(messages.halfButton)}</StyledCounterPartTd>
              <StyledCounterPartValueTd>{counter.buttonHalf}</StyledCounterPartValueTd>
            </StyledCounterTr>
            <StyledCounterTr>
              <StyledCounterPartTd>{intl.formatMessage(messages.facebookButton)}</StyledCounterPartTd>
              <StyledCounterPartValueTd>{counter.facebookShareButton}</StyledCounterPartValueTd>
            </StyledCounterTr>
            <StyledCounterTr>
              <StyledCounterPartTd>{intl.formatMessage(messages.unityButton)}</StyledCounterPartTd>
              <StyledCounterPartValueTd>{counter.buttonUnity}</StyledCounterPartValueTd>
            </StyledCounterTr>
            <StyledCounterTr>
              <StyledCounterPartTd>{intl.formatMessage(messages.languageLink)}</StyledCounterPartTd>
              <StyledCounterPartValueTd>{counter.languageLink}</StyledCounterPartValueTd>
            </StyledCounterTr>
          </tbody>
        </StyledTableCounter>
      </div>
      <div style={{display:'flex', marginTop: '20px'}}>
        <StyledTableCounter>
          <StyledCounterThead>
            <StyledCounterTr>
              <StyledCounterHeadTd colSpan={2}>{intl.formatMessage(messages.sectionIntegration)}</StyledCounterHeadTd>
            </StyledCounterTr>
          </StyledCounterThead>
          <tbody>
            <StyledCounterTr>
              <StyledCounterPartTd>{intl.formatMessage(messages.googleMaps)}</StyledCounterPartTd>
              <StyledCounterPartValueTd>{counter.googleMaps}</StyledCounterPartValueTd>
            </StyledCounterTr>
            <StyledCounterTr>
              <StyledCounterPartTd>{intl.formatMessage(messages.googleForm)}</StyledCounterPartTd>
              <StyledCounterPartValueTd>{counter.formGoogle}</StyledCounterPartValueTd>
            </StyledCounterTr>
          </tbody>
        </StyledTableCounter>
        <StyledTableCounter>
          <StyledCounterThead>
            <StyledCounterTr>
              <StyledCounterHeadTd colSpan={2}>{intl.formatMessage(messages.sectionRedirect)}</StyledCounterHeadTd>
            </StyledCounterTr>
          </StyledCounterThead>
          <tbody>
            <StyledCounterTr>
              <StyledCounterPartTd>{intl.formatMessage(messages.redirect)}</StyledCounterPartTd>
              <StyledCounterPartValueTd>{counter.redirect}</StyledCounterPartValueTd>
            </StyledCounterTr>
            <StyledCounterTr>
              <StyledCounterPartTd>{intl.formatMessage(messages.randomRedirect)}</StyledCounterPartTd>
              <StyledCounterPartValueTd>{counter.random}</StyledCounterPartValueTd>
            </StyledCounterTr>
            <StyledCounterTr>
              <StyledCounterPartTd>{intl.formatMessage(messages.randomReset)}</StyledCounterPartTd>
              <StyledCounterPartValueTd>{counter.randomCancel}</StyledCounterPartValueTd>
            </StyledCounterTr>
            <StyledCounterTr>
              <StyledCounterPartTd>{intl.formatMessage(messages.randomStory)}</StyledCounterPartTd>
              <StyledCounterPartValueTd>{counter.randomStory}</StyledCounterPartValueTd>
            </StyledCounterTr>
            <StyledCounterTr>
              <StyledCounterPartTd>{intl.formatMessage(messages.randomStoryReset)}</StyledCounterPartTd>
              <StyledCounterPartValueTd>{counter.randomStoryReset}</StyledCounterPartValueTd>
            </StyledCounterTr>
          </tbody>
        </StyledTableCounter>
        <StyledTableCounter>
          <StyledCounterThead>
            <StyledCounterTr>
              <StyledCounterHeadTd colSpan={2}>{intl.formatMessage(messages.sectionLock)}</StyledCounterHeadTd>
            </StyledCounterTr>
          </StyledCounterThead>
          <tbody>
            <StyledCounterTr>
              <StyledCounterPartTd>{intl.formatMessage(messages.lock)}</StyledCounterPartTd>
              <StyledCounterPartValueTd>{counter.lock}</StyledCounterPartValueTd>
            </StyledCounterTr>
            <StyledCounterTr>
              <StyledCounterPartTd>{intl.formatMessage(messages.lockReset)}</StyledCounterPartTd>
              <StyledCounterPartValueTd>{counter.lockCancel}</StyledCounterPartValueTd>
            </StyledCounterTr>
            <StyledCounterTr>
              <StyledCounterPartTd>{intl.formatMessage(messages.lockPage)}</StyledCounterPartTd>
              <StyledCounterPartValueTd>{counter.lockPage}</StyledCounterPartValueTd>
            </StyledCounterTr>
          </tbody>
        </StyledTableCounter>
        <StyledTableCounter>
          <StyledCounterThead>
            <StyledCounterTr>
              <StyledCounterHeadTd colSpan={2}>{intl.formatMessage(messages.sectionPremium)}</StyledCounterHeadTd>
            </StyledCounterTr>
          </StyledCounterThead>
          <tbody>
            <StyledCounterTr>
              <StyledCounterPartTd>{intl.formatMessage(messages.voxpop)}</StyledCounterPartTd>
              <StyledCounterPartValueTd>{counter.voxpop}</StyledCounterPartValueTd>
            </StyledCounterTr>
            <StyledCounterTr>
              <StyledCounterPartTd>{intl.formatMessage(messages.photobooth)}</StyledCounterPartTd>
              <StyledCounterPartValueTd>{counter.photobooth}</StyledCounterPartValueTd>
            </StyledCounterTr>
            <StyledCounterTr>
              <StyledCounterPartTd>{intl.formatMessage(messages.list)}</StyledCounterPartTd>
              <StyledCounterPartValueTd>{counter.list}</StyledCounterPartValueTd>
            </StyledCounterTr>
          </tbody>
        </StyledTableCounter>
        <StyledTableCounter>
          <StyledCounterThead>
            <StyledCounterTr>
              <StyledCounterHeadTd colSpan={4}>{intl.formatMessage(messages.sectionModule)}</StyledCounterHeadTd>
            </StyledCounterTr>
          </StyledCounterThead>
          <tbody>
            <StyledCounterTr>
              <StyledCounterPartTd>{intl.formatMessage(messages.trigger)}</StyledCounterPartTd>
              <StyledCounterPartValueTd>{counter.trigger}</StyledCounterPartValueTd>
            </StyledCounterTr>
            <StyledCounterTr>
              <StyledCounterPartTd>{intl.formatMessage(messages.product)}</StyledCounterPartTd>
              <StyledCounterPartValueTd>{counter.listItem}</StyledCounterPartValueTd>
            </StyledCounterTr>
            <StyledCounterTr>
              <StyledCounterPartTd>{intl.formatMessage(messages.input)}</StyledCounterPartTd>
              <StyledCounterPartValueTd>{counter.input}</StyledCounterPartValueTd>
            </StyledCounterTr>
          </tbody>
        </StyledTableCounter>
      </div>
    </>
  )
}

Counter.propTypes = {
  list: PropTypes.array,
}

export default Counter
