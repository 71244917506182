import { createSagas } from 'redux-box'
import { call, put, take } from 'redux-saga/effects'
import rsFirebase from 'services/rsf'
import { uploadMediaFile } from '../../services/fileManager'
import { rteAppId, rteLockId } from '../../utils/routes'

export default createSagas({
  *LOCK_GET_LIST({ appId }) {
    try {
      const collection = yield call(rsFirebase.firestore.getCollection, `appdata/${appId}/locks`)
      const data = {}

      collection.forEach((document) => {
        const doc = document.data()
        data[doc.id] = doc
      })

      yield put({ type: 'LOCK_SET_LIST', data })
    } catch (error) {
      yield put({ type: 'LOCK_ERROR' })
    }
  },
  *LOCK_SAVE({ appId, cardId, lock, onSave = () => {} }) {
    try {
      const { id } = lock
      const path = `appdata/${appId}/locks/${id}`

      for (const key in lock) {
        if (lock[key] instanceof File) {
          lock[key] = yield call(uploadMediaFile, lock[key], `/apps/${appId}/locks/${id}`);
        } else if (typeof lock[key] === 'object' && lock[key] !== null) {
          for (const nestedKey in lock[key]) {
            if (lock[key][nestedKey] instanceof File) {
              lock[key][nestedKey] = yield call(uploadMediaFile, lock[key][nestedKey], `/apps/${appId}/locks/${id}`);
            }
          }
        }
      }

      const data = {
        id,
        dateUpdated: new Date(Date.now()),
        appId,
        cardId: cardId || '',
        ...lock,
      }
      yield put({ type: 'LOCK_SAVING', module })
      yield call(rsFirebase.firestore.setDocument, path, data, { merge: true })

      yield put({
        type: 'LOGS_ADD_LOGS_CONTENT',
        appId: appId,
        action: 'save',
        where: rteLockId(appId, id),
        typeContent: 'locks',
        content: id
      });
      yield take('LOGS_ADD_LOGS_SUCCESS');
      yield put({ type: 'LOGS_ADD_LOGS_RESET_SUCCESS' })

      yield put({ type: 'LOCK_ADD_TO_LIST', data })
      yield put({ type: 'LOCK_SAVE_SUCCESS', module })
      onSave(id)
    } catch (error) {
      yield put({ type: 'LOCK_SAVE_ERROR' })
    }
  },
  *LOCK_DELETE({ id, appId }) {
    try {
      yield put({
        type: 'LOGS_ADD_LOGS_CONTENT',
        appId: appId,
        action: 'delete',
        where: rteAppId(appId, 'LOCKS'),
        typeContent: 'locks',
        content: id
      });
      yield take('LOGS_ADD_LOGS_SUCCESS');
      yield put({ type: 'LOGS_ADD_LOGS_RESET_SUCCESS' })

      yield put({ type: 'LOCK_DELETING' })
      yield call(rsFirebase.firestore.deleteDocument, `appdata/${appId}/locks/${id}`)
      yield put({ type: 'LOCK_DELETE_FROM_LIST', id })
      yield put({ type: 'LOCK_DELETE_SUCCESS', module })
    } catch (error) {
      yield put({ type: 'LOCK_SAVE_ERROR' })
    }
  },
})
