// TODO: MAKE IT BACKEND! ;)
export const allFeatures = {
  Audio: { id: 'Audio', type: 'part' },
  Bandcamp: { id: 'Bandcamp', type: 'part' },
  BtnUnity: { id: 'BtnUnity', type: 'part' },
  Button: { id: 'Button', type: 'part' },
  ButtonHalf: { id: 'ButtonHalf', type: 'part' },
  FacebookShareButton: { id: 'FacebookShareButton', type: 'part' },
  Folders: { id: 'Folders', type: 'feature' },
  FormGoogle: { id: 'FormGoogle', type: 'part' },
  Giphy: { id: 'Giphy', type: 'part' },
  GoogleMaps: { id: 'GoogleMaps', type: 'part' },
  H2: { id: 'H2', type: 'part' },
  Input: { id: 'Input', type: 'feature' },
  LanguageLink: { id: 'LanguageLink', type: 'part' },
  List: { id: 'List', type: 'feature' },
  ListItem: { id: 'ListItem', type: 'feature' },
  Lock: { id: 'Lock', type: 'part' },
  LockCancel: { id: 'LockCancel', type: 'part' },
  LockPage: { id: 'LockPage', type: 'part' },
  MSJBrowser: { id: 'MSJBrowser', type: 'feature' },
  MultiplePicture: { id: 'MultiplePicture', type: 'part' },
  Photobooth: { id: 'Photobooth', type: 'part' },
  Picture: { id: 'Picture', type: 'part' },
  PictureUrl: { id: 'PictureUrl', type: 'part' },
  Random: { id: 'Random', type: 'part' },
  RandomStory: { id: 'RandomStory', type: 'part' },
  RandomStoryReset: { id: 'RandomStoryReset', type: 'part' },
  RawEditor: { id: 'RawEditor', type: 'feature' },
  Redirect: { id: 'Redirect', type: 'part' },
  Schedule: { id: 'Schedule', type: 'feature' },
  Separator: { id: 'Separator', type: 'part' },
  SketchFab: { id: 'SketchFab', type: 'part' },
  SoundCloud: { id: 'SoundCloud', type: 'part' },
  SurveyMonkeyForm: { id: 'SurveyMonkeyForm', type: 'part' },
  Text: { id: 'Text', type: 'part' },
  Timer: { id: 'Timer', type: 'feature' },
  Trigger: { id: 'Trigger', type: 'feature' },
  Video: { id: 'Video', type: 'part' },
  VideoYoutube: { id: 'VideoYoutube', type: 'part' },
  Vimeo: { id: 'Vimeo', type: 'part' },
  VisitCounter: { id: 'VisitCounter', type: 'part' },
  Voxpop: { id: 'Voxpop', type: 'part' },
  Favicon: { id: 'Favicon', type: 'feature' },
}

const planMinimal = {
  id: 'minimal',
  features: [
    allFeatures.Bandcamp,
    allFeatures.Button,
    allFeatures.ButtonHalf,
    allFeatures.FacebookShareButton,
    allFeatures.FormGoogle,
    allFeatures.Giphy,
    allFeatures.GoogleMaps,
    allFeatures.H2,
    allFeatures.Picture,
    allFeatures.PictureUrl,
    allFeatures.Redirect,
    allFeatures.Separator,
    allFeatures.SketchFab,
    allFeatures.SoundCloud,
    allFeatures.SurveyMonkeyForm,
    allFeatures.Text,
    allFeatures.Video,
    allFeatures.VideoYoutube,
    allFeatures.Vimeo,
  ],
}

const planClassic = {
  id: 'classic',
  features: [
    ...planMinimal.features,
    allFeatures.Audio,
    allFeatures.Random,
    allFeatures.Lock,
    allFeatures.MSJBrowser,
    allFeatures.Folders,
    allFeatures.LanguageLink,
  ],
}

const planFull = {
  id: 'latotale',
  features: [
    ...planClassic.features,
    allFeatures.Schedule,
    allFeatures.Timer,
    allFeatures.List,
    allFeatures.ListItem,
    allFeatures.LockCancel,
    allFeatures.LockPage,
    allFeatures.Input,
    allFeatures.Trigger,
    allFeatures.Photobooth,
    allFeatures.RandomStory,
    allFeatures.RandomStoryReset,
    allFeatures.LanguageLink,
  ],
}

const planAdmin = {
  id: 'admin',
  features: [
    ...planFull.features,
    allFeatures.BtnUnity,
    allFeatures.MultiplePicture,
    allFeatures.RawEditor,
    allFeatures.VisitCounter,
    allFeatures.Voxpop
  ],
}

const planApp = {
  id: 'app',
  features: [
    allFeatures.Audio,
    allFeatures.Button,
    allFeatures.ButtonHalf,
    allFeatures.Folders,
    allFeatures.H2,
    allFeatures.Picture,
    allFeatures.Separator,
    allFeatures.Text,
  ],
}

export const plans = {
  app: planApp,
  minimal: planMinimal,
  classic: planClassic,
  latotale: planFull,
  admin: planAdmin,
}

export const getAccess = ({ plan = 'minimal', addOns }) => {
  const list = []
  plans[plan].features.map((f) => (list[f.id] = { ...f }))

  if (addOns && Object.keys(addOns).length > 0) {
    Object.values(addOns).map((a) => (list[a.id] = { ...allFeatures[a.id] }))
  }

  return list
}

// 'allFeatures' is already declared in the upper scope.
export const getFeatureListByPlanAndAddOns = ({ plan = 'minimal', addOns = [] }) => {
  const accessList = {}
  // list of features includes in the plan
  if (plans[plan]) {
    plans[plan].features.forEach((feature) => {
      accessList[feature.id] = feature
    })
  }

  // list of addOns
  addOns.forEach((addOnName) => {
    if (allFeatures[addOnName]) {
      accessList[addOnName] = allFeatures[addOnName]
    }
  })

  return accessList
}

export const getAddOnsNotInPlan = (plan = 'minimal') => {
  const addOns = {}

  if (plan && plans[plan] && plans[plan].features) {
    Object.values(allFeatures).forEach((feature) => {
      if (!Object.values(plans[plan].features).includes(feature)) {
        addOns[feature.id] = feature
      }
    })
  }

  return addOns
}
