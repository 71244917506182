const mutations = {
  LOGS_ADD: (state, { log }) => {
    state.list.push(log)
  },
  LOGS_SET: (state, { list }) => {
    state.list = list
    state.fetching = false
  },
  LOGS_SESSION_SET: (state, { sessions }) => {
    state.sessions = sessions
  },
  LOGS_FETCHING: (state) => {
    state.fetching = true
  },
  LOGS_FETCHING_ERROR: (state) => {
    state.fetching = false
    state.error = true
  },
  LOGS_ADD_LOGS_SUCCESS: (state) => {
    state.success = true
  },
  LOGS_ADD_LOGS_RESET_SUCCESS: (state) => {
    state.success = false
  },
}
export default mutations
