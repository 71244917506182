const RTE = {
  NOTFOUND: { path: '/:appId/*', name: 'Page not found' },

  LOGIN: { path: '/login', name: 'Login' },
  LOST_PASSWORD: { path: '/lost-password', name: 'Lost Password' },
  RESET_PASSWORD: { path: '/reset-password', name: 'Reset Password' },
  INIT_PASSWORD: { path: '/init-password', name: 'Init Password' },
  LOGOUT: { path: '/logout', name: 'Logout' },

  CARDS: { path: '/:appId/pages/linked', name: 'Pages' },
  CARDS_TAG: { path: '/:appId/pages/:tagId', name: 'Pages by tag' },

  ONE_CARD: { path: '/:appId/page/:cardId', name: 'View page' },
  ONE_CARD_EDIT: { path: '/:appId/page/:cardId/edit', name: 'Edit page' },
  ONE_CARD_NEW: { path: '/:appId/page/:cardId/new', name: 'New page' },

  FOLDERS: { path: '/:appId/folders/', name: 'Projects' },
  FOLDER: { path: '/:appId/folder/:folderId/', name: 'Project' },
  FOLDER_TAG: { path: '/:appId/folders/:folderId/:tagId', name: 'Project by tag' },

  MANAGEAPPS: { path: '/:appId/management/apps', name: 'Manage apps' },
  MANAGEAPPS_SINGLE: { path: '/:appId/management/apps/:id', name: 'Manage one app' },
  MANAGEUSERS: { path: '/:appId/manageusers', name: 'Manage users' },
  MANAGEUSERS_SINGLE: { path: '/:appId/manageusers/:id', name: 'Manage one user' },

  HOME: { path: '/:appId', name: 'MyApp' },
  FEEDBACKS: { path: '/:appId/feedbacks', name: 'Feedbacks' },
  DATA: { path: '/:appId/data', name: 'Data' },
  LOGS: { path: '/:appId/logs', name: 'Logs' },
  TUTORIAL: { path: '/:appId/tutorial', name: 'Tutorial' },
  SETTINGS: { path: '/:appId/settings', name: 'Settings' },
  CONTACT: { path: '/:appId/contact', name: 'Contact' },

  VOXPOP: { path: '/:appId/voxpop', name: 'VoxPop' },
  VOXPOPFILES: { path: '/:appId/voxpop/:voxpopId', name: 'VoxPopFiles' },

  LOCKS: { path: '/:appId/locks', name: 'Locks' },
  LOCKS_SINGLE: { path: '/:appId/locks/:id', name: 'One lock' },
  RANDOM_STORY: { path: '/:appId/randomstories', name: 'Random Stories' },
  RANDOM_STORY_SINGLE: { path: '/:appId/randomstory/:id', name: 'One Random Story' },
  LISTS: { path: '/:appId/modules/lists', name: 'Lists' },
  LISTS_SINGLE: { path: '/:appId/modules/lists/:id', name: 'One list' },
  ITEMS: { path: '/:appId/modules/items', name: 'Items' },
  ITEMS_SINGLE: { path: '/:appId/modules/items/:id', name: 'One item' },
  TRIGGERS: { path: '/:appId/modules/triggers', name: 'Triggers' },
  TRIGGERS_SINGLE: { path: '/:appId/modules/triggers/:id', name: 'One trigger' },
  INPUTS: { path: '/:appId/modules/inputs', name: 'Inputs' },
  INPUTS_SINGLE: { path: '/:appId/modules/inputs/:id', name: 'One input' },
  PHOTOBOOTHS: { path: '/:appId/modules/selfiebooths', name: 'Selfie Booths' },
  PHOTOBOOTHS_SINGLE: { path: '/:appId/modules/selfiebooths/:id', name: 'One Selfie Booth' },
}

export const rteAppId = (appId, rte) => RTE[rte].path.replace(':appId', appId)

export const rteCardId = (appId, cardId) =>
  RTE.ONE_CARD.path.replace(':appId', appId).replace(':cardId', cardId)

export const rteCardEditId = (appId, cardId) =>
  RTE.ONE_CARD_EDIT.path.replace(':appId', appId).replace(':cardId', cardId)

export const rteNewCard = (appId, cardId) =>
  RTE.ONE_CARD_NEW.path.replace(':appId', appId).replace(':cardId', cardId)

export const rteCardsTag = (appId, tag) => RTE.CARDS_TAG.path.replace(':appId', appId).replace(':tagId', tag)

export const rteFolderTag = (appId, folderId, tag) =>
  RTE.FOLDER_TAG.path.replace(':appId', appId).replace(':folderId', folderId).replace(':tagId', tag)

export const rteModulesId = (appId, rte, id) => RTE[rte].path.replace(':appId', appId).replace(':id', id)

export const rteManageAppId = (appId, id) =>
  RTE.MANAGEAPPS_SINGLE.path.replace(':appId', appId).replace(':id', id)

export const rteManageUserId = (appId, id) =>
  RTE.MANAGEUSERS_SINGLE.path.replace(':appId', appId).replace(':id', id)

export const rteLockId = (appId, id) => RTE.LOCKS_SINGLE.path.replace(':appId', appId).replace(':id', id)

export const rteRandomStoryId = (appId, id) => RTE.RANDOM_STORY_SINGLE.path.replace(':appId', appId).replace(':id', id)

export const rtePhotoboothId = (appId, id) =>
  RTE.PHOTOBOOTHS_SINGLE.path.replace(':appId', appId).replace(':id', id)

export default RTE
