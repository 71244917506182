/**
 * Create the store with dynamic reducers
 */
import { applyMiddleware, combineReducers, compose, createStore } from 'redux'
import createSagaMiddleware from 'redux-saga'
import { all } from 'redux-saga/effects'
import { moduleToReducer } from 'redux-box'

import { routerReducer, routerMiddleware } from 'react-router-redux'

import { storeModule as appModule } from './app'
import { storeModule as cardModule } from './card'
import { storeModule as cardListModule } from './cardList'
import { storeModule as manageAppsModule } from './manageApps'
import { storeModule as manageUsersModule } from './manageUsers'
import { storeModule as dataModule } from './data'
import { storeModule as voxpopModule } from './voxpop'
import { storeModule as locksModule } from './locks'
import { storeModule as logsModule } from './logs'
import { storeModule as randomStoryModule } from './randomStory'
import { storeModule as modulesModule } from './modules'

export default function configureStore(initialState = {}, history) {
  // hook up your module reducers
  const combinedReducer = combineReducers({
    app: moduleToReducer(appModule),
    card: moduleToReducer(cardModule),
    cardList: moduleToReducer(cardListModule),
    manageApps: moduleToReducer(manageAppsModule),
    manageUsers: moduleToReducer(manageUsersModule),
    data: moduleToReducer(dataModule),
    voxpop: moduleToReducer(voxpopModule),
    locks: moduleToReducer(locksModule),
    logs: moduleToReducer(logsModule),
    randomStory: moduleToReducer(randomStoryModule),
    modules: moduleToReducer(modulesModule),
    router: routerReducer,
  })

  // hook up your module sagas
  const sagas = [
    ...appModule.sagas,
    ...cardModule.sagas,
    ...cardListModule.sagas,
    ...manageAppsModule.sagas,
    ...manageUsersModule.sagas,
    ...dataModule.sagas,
    ...voxpopModule.sagas,
    ...locksModule.sagas,
    ...logsModule.sagas,
    ...randomStoryModule.sagas,
    ...modulesModule.sagas,
  ]

  // hook up your middlewares here
  const sagaMiddleware = createSagaMiddleware()
  const middlewares = [sagaMiddleware, routerMiddleware(history)]

  // what follows below is the usual approach of setting up store
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose // eslint-disable-line
  const enhancer = composeEnhancers(applyMiddleware(...middlewares))

  function* rootSaga() {
    yield all(sagas)
  }

  const store = createStore(combinedReducer, enhancer)

  sagaMiddleware.run(rootSaga)

  return store
}
