import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { StyledContainer, StyledElement, StyledInput, StyledSelect } from './styles'
import DateFilter from './dateFilters'
import { useIntl } from 'react-intl'
import messages from './messages'

const SearchSessions = ({ searchQuery, setSearchQuery }) => {
  const [user, setUser] = useState(searchQuery.user || '')
  const [action, setAction] = useState(searchQuery.action || '')
  const [dateRange, setDateRange] = useState(searchQuery.dateRange || [null, null])

  const intl = useIntl();

  useEffect(() => {
    document.addEventListener('keydown', handleOnKeyDown)

    return () => {
      document.removeEventListener('keydown', handleOnKeyDown)
    }
  }, [])

  useEffect(() => {
    setSearchQuery({ user, action, dateRange })
  }, [user, action, dateRange, setSearchQuery])

  const handleOnKeyDown = (e) => {
    if (e.key === 'Enter') {
      setSearchQuery({ user, action, dateRange })
    }
  }

  const handleActionChange = (e) => {
    const selectedAction = e.target.value;
    setAction(selectedAction);
  }

  return (
    <StyledContainer>
      <StyledElement>
        <p>{intl.formatMessage(messages.filterEmail)}</p>
        <StyledInput
          type="text"
          value={user}
          onChange={(e) => setUser(e.target.value)}
        />
      </StyledElement>
      <StyledElement>
        <p>{intl.formatMessage(messages.filterAction)}</p>
        <StyledSelect
          value={searchQuery.action}
          onChange={handleActionChange}>
          <option value=""></option>
          <option value="login">Login</option>
          <option value="logout">Logout</option>
        </StyledSelect>
      </StyledElement>
      <StyledElement>
        <p>{intl.formatMessage(messages.filterPeriod)}</p>
        <DateFilter value={dateRange} onChange={setDateRange} />
      </StyledElement>
    </StyledContainer>
  )
}

SearchSessions.defaultProps = {
  searchSessionsQuery: {},
  setSearchQuery: () => {},
}

SearchSessions.propTypes = {
  placeholder: PropTypes.string,
  searchQuery: PropTypes.object,
  setSearchQuery: PropTypes.func,
}

export default SearchSessions
