import { call } from 'redux-saga/effects'
import uuidv4 from 'uuid/v4'

import rsFirebase from './rsf'
import { splitFileName } from './utils'
import uid from 'uid'

export function* getFileInfos(path) {
  const metadata = yield call(rsFirebase.storage.getFileMetadata, path)
  const url = yield call(rsFirebase.storage.getDownloadURL, path)

  return { url, metadata }
}

export function* uploadFile64(string, url) {
  yield call(rsFirebase.storage.uploadString, url, string, 'base64')
  return yield getFileInfos(url)
}

export function* uploadFile(file, url) {
  yield call(rsFirebase.storage.uploadFile, url, file)
  return yield getFileInfos(url)
}

// create a file in the DB
export function* createFile(appId, fileType, file) {
  const id = uuidv4()
  const path = `appdata/${appId}/files-uploaded/${id}`
  const data = {
    id,
    dateAdded: new Date(Date.now()),
    fileType, // image or video
    file, // { name, location, url, size, width, height, type (mimetype) }
  }
  try {
    yield call(rsFirebase.firestore.setDocument, path, data, { merge: true })
    return id
  } catch (error) {
    return error
  }
}

export function* updateVideoFile(appId, fileId, url) {
  const path = `appdata/${appId}/files-uploaded/${fileId}`
  const data = {
    file: {
      location: 'vimeo',
      url,
    },
  }
  try {
    yield call(rsFirebase.firestore.setDocument, path, data, { merge: true })
    return true
  } catch (error) {
    return error
  }
}

export function* uploadMediaFile(data, path) {
  const fileNameObj = splitFileName(data.name);

  const fileName = `${uid('10')}.${fileNameObj.ext}`

  const pathWithName = `${path}/${fileName}`

  const fileInfos = data.base64
    ? yield uploadFile64(data.base64.slice(23), pathWithName)
    : yield uploadFile(data, pathWithName)

  delete data.base64

  return fileInfos.url
}
